import imgCompany from "../../../image/logo/company_image.jpg";
import imgAbout1 from "../../../image/about/about_1.jpg";
import imgAbout2 from "../../../image/about/about_2.jpg";
import imgAbout3 from "../../../image/about/about_3.jpg";
import imgAbout4 from "../../../image/about/about_4.jpg";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Social from '../Social/Social';
import { LanguageContext } from "../../../context/LanguageContext";
import { useContext, useEffect } from 'react';

export default function About() {
    const listAboutContent = [{
        id: 1,
        lang: "vi",
        contentItem1: {
            id: 1,
            title: "PHƯƠNG CHÂM HOẠT ĐỘNG",
            contentDetail: [
                "Công ty chúng tôi luôn phát triển với phương châm: ",
                "Phát triển không chỉ là tăng trưởng.",
                "Các sản phẩm được sản xuất bền đẹp, đáp ứng tiêu chuẩn cao, mang đến sự hài lòng cho khách hàng. Bên cạnh đó, việc phát triển bền vũng là rất cần thiết. Chúng tôi luôn ưu tiên sử dụng nguồn nguyên liệu gỗ hợp pháp, thân thiện với môi trường, góp phần bảo vệ tài nguyên thiên nhiên",
            ]
        },
        contentItem2: {
            id: 2,
            title: "TẦM NHÌN VÀ SỨ MỆNH",
            contentDetail: [
                "Tầm nhìn: ",
                "Trở thành thương hiệu gỗ được yêu thích nhất, đồng hành cùng mọi gia đình và doanh nghiệp.",
                "Sứ mệnh: ",
                "Tạo ra giá trị vượt thời gian thông qua các sản phẩm của chúng tôi, mang lại sự hài lòng tối đa cho khách hàng.",
            ]
        },
        contentItem3: {
            id: 3,
            title: "GIÁ TRỊ CỐT LÕI",
            contentDetail: [
                "Chúng tôi không ngừng sáng tạo và cố gắng dẫn đầu xu hướng. Luôn cải tiến công nghệ, thiết kế sản phẩm độc đáo, đáp ứng nhu cầu đa dạng của khách hàng. Đặt lợi ích của khách hàng lên hàng đầu, cung cấp dịch vụ tận tâm và chuyên nghiệp.",
            ]
        },
        contentItem4: {
            id: 4,
            title: "ĐIỂM NHẤN",
            contentDetail: [
                "Chất lượng vượt trội: Nguyên liệu được chọn lọc kỹ càng, xử lý đạt tiêu chuẩn cao nhất.",
                "Đội ngũ lành nghề: Những người thợ mộc tâm huyết, với tay nghề khéo léo, luôn mang đến sản phẩm hoàn hảo.",
                "Giá trị bền vững: Sử dụng gỗ từ nguồn cung ứng bền vững, thân thiện với môi trường.",
                "Dịch vụ chuyên nghiệp: Tư vấn tận tâm, thiết kế sáng tạo, bảo hành chu đáo.",
                "Đồng hành tiến bước: Kiến tạo không gian sống đẳng cấp, trọn vẹn và khác biệt",
            ]
        },
    },
    {
        id: 2,
        lang: "en",
        contentItem1: {
            id: 1,
            title: "THE MOTTO OF OPERATION",
            contentDetail: [
                "Our company is constantly growing with the motto of operation: ",
                "Development is not just about growth.",
                "Our products are crafted durable and beautiful, meeting high standards to ensure customer satisfaction. Additionally, sustainable development is essential. We always prioritize using legally sourced wood materials that are environmentally friendly, contributing to protecting natural resources.",
            ]
        },
        contentItem2: {
            id: 2,
            title: "VISION AND MISSION",
            contentDetail: [
                "Vision: ",
                "Become the most favorite wood brand, accompanying every family and business.",
                "Mission: ",
                "Creating timeless value through our products, bringing maximum satisfaction to customers.",
            ]
        },
        contentItem3: {
            id: 3,
            title: "CORE VALUES",
            contentDetail: [
                "We continuously innovate and strive to lead trends. Constantly improving technology and designing unique products to meet the diverse needs of our customers. Placing customer interests first, we provide dedicated and professional services.",
            ]
        },
        contentItem4: {
            id: 4,
            title: "HIGHLIGHT",
            contentDetail: [
                "Outstanding quality: The materials are carefully selected and processed to meet the highest standards.",
                "Skilled team: Passionate craftsmen with skilled workmanship, always delivering perfect products.",
                "Sustainable value: Using wood from sustainable, environmentally friendly sources.",
                "Professional Services: Dedicated Consulting, Creative Design, Thoughtful Warranty.",
                "Accompanying Your Progress: Creating a Sophisticated, Complete, and Unique Living Space.",
            ]
        },
    },
    ];

    const { language, setLanguage } = useContext(LanguageContext);
    useEffect(() => {
    }, [language]);

    return (
        <div className="w-full h-[100%]">
            <Header />
            <div className="w-full h-full bg-slate-50">
                <img className="w-full h-[50vh] bg-contain" src={imgCompany} />
                <div className="w-[80%] h-[50%] m-auto my-5">
                    <div className="w-full h-full" >
                        {
                            listAboutContent.map((item) => {
                                return (
                                    item.lang === language ?
                                        <div>
                                            <div className='grid sm:grid-cols-2 max-sm:grid-cols-1 gap-6 w-full py-5'>
                                                <img className="w-full h-full bg-contain" src={imgAbout1} />
                                                <div>
                                                    <h1 className="text-amber-500 font-bold text-2xl max-lg:text-[13px]">{item.contentItem1.title}</h1>
                                                    <p className="leading-8 max-lg:text-[12px]">
                                                        {item.contentItem1.contentDetail[0]} <span className='font-bold text-xl max-lg:text-[12px]'>{item.contentItem1.contentDetail[1]}</span><br />
                                                        {item.contentItem1.contentDetail[2]}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='grid sm:grid-cols-2 max-sm:grid-cols-1 gap-6 w-full py-5'>
                                                <div>
                                                    <h1 className="text-amber-500 font-bold text-2xl max-lg:text-[13px]">{item.contentItem2.title}</h1>
                                                    <p className="leading-8 max-lg:text-[12px]">
                                                        <span className='font-bold text-xl max-lg:text-[12px]'>{item.contentItem2.contentDetail[0]}</span> {item.contentItem2.contentDetail[1]}<br />
                                                        <span className='font-bold text-xl max-lg:text-[12px]'>{item.contentItem2.contentDetail[2]}</span> {item.contentItem2.contentDetail[3]}
                                                    </p>
                                                </div>
                                                <img className="w-full h-full bg-contain" src={imgAbout2} />
                                            </div>
                                            <div className='grid sm:grid-cols-2 max-sm:grid-cols-1 gap-6 w-full py-5'>
                                                <img className="w-full h-full bg-contain" src={imgAbout3} />
                                                <div>
                                                    <h1 className="text-amber-500 font-bold text-2xl max-lg:text-[13px]">{item.contentItem3.title}</h1>
                                                    <p className="leading-8 max-lg:text-[12px]">
                                                        {item.contentItem3.contentDetail[0]}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='grid sm:grid-cols-2 max-sm:grid-cols-1 gap-6 w-full py-5'>
                                                <div>
                                                    <h1 className="text-amber-500 font-bold text-2xl max-lg:text-[13px]">{item.contentItem4.title}</h1>
                                                    <ul className="list-inside list-disc">
                                                        <li className="lg:text-base max-lg:text-[12px]">{item.contentItem4.contentDetail[0]}</li>
                                                        <li className="lg:text-base max-lg:text-[12px]">{item.contentItem4.contentDetail[1]}</li>
                                                        <li className="lg:text-base max-lg:text-[12px]">{item.contentItem4.contentDetail[2]}</li>
                                                        <li className="lg:text-base max-lg:text-[12px]">{item.contentItem4.contentDetail[3]}</li>
                                                        <li className="lg:text-base max-lg:text-[12px]">{item.contentItem4.contentDetail[4]}</li>
                                                    </ul>
                                                </div>
                                                <img className="w-full h-full bg-contain" src={imgAbout4} />
                                            </div>
                                        </div>
                                        : ""
                                )
                            })
                        }
                        {/* <div className='grid grid-cols-2 gap-6 w-full py-5'>
                            <img className="w-full h-full bg-contain" src={imgAbout1} />
                            <div>
                                <h1 className="text-amber-500 font-bold text-2xl">PHƯƠNG CHÂM HOẠT ĐỘNG</h1>
                                <p className="leading-10">
                                    Công ty chúng tôi luôn phát triển với phương châm: <span className='font-bold text-xl'>Phát triển không chỉ là tăng trưởng.</span><br />
                                    Các sản phẩm được sản xuất bền đẹp, đáp ứng tiêu chuẩn cao, mang đến sự hài lòng cho khách hàng. Bên cạnh đó, việc phát triển bền vũng là rất cần thiết. Chúng tôi luôn ưu tiên sử dụng nguồn nguyên liệu gỗ hợp pháp, thân thiện với môi trường, góp phần bảo vệ tài nguyên thiên nhiên
                                </p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-6 w-full py-5'>
                            <div>
                                <h1 className="text-amber-500 font-bold text-2xl">TẦM NHÌN VÀ SỨ MỆNH</h1>
                                <p className="leading-10">
                                    <span className='font-bold text-xl'>Tầm nhìn:</span> Trở thành thương hiệu gỗ được yêu thích nhất, đồng hành cùng mọi gia đình và doanh nghiệp.<br />
                                    <span className='font-bold text-xl'>Sứ mệnh:</span> Tạo nên giá trị vượt thời gian thông qua các sản phẩm gỗ bền vững, góp phần bảo vệ môi trường và mang lại sự hài lòng tối đa cho khách hàng.
                                </p>
                            </div>
                            <img className="w-full h-full bg-contain" src={imgAbout2} />
                        </div>
                        <div className='grid grid-cols-2 gap-6 w-full py-5'>
                            <img className="w-full h-full bg-contain" src={imgAbout3} />
                            <div>
                                <h1 className="text-amber-500 font-bold text-2xl">GIÁ TRỊ CỐT LÕI</h1>
                                <p className="leading-10">
                                    Chúng tôi không ngừng sáng tạo và cố gắng dẫn đầu xu hướng. Luôn cải tiến công nghệ, thiết kế sản phẩm độc đáo, đáp ứng nhu cầu đa dạng của khách hàng. Đặt lợi ích của khách hàng lên hàng đầu, cung cấp dịch vụ tận tâm và chuyên nghiệp.
                                </p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-6 w-full py-5'>
                            <div>
                                <h1 className="text-amber-500 font-bold text-2xl">ĐIỂM NHẤN</h1>
                                <ul className="list-inside list-disc">
                                    <li className="lg:text-base sm:text-[10px]">Chất lượng vượt trội: Nguyên liệu được chọn lọc kỹ càng, xử lý đạt tiêu chuẩn cao nhất.</li>
                                    <li className="lg:text-base sm:text-[10px]">Đội ngũ lành nghề: Những người thợ mộc tâm huyết, với tay nghề khéo léo, luôn mang đến sản phẩm hoàn hảo.</li>
                                    <li className="lg:text-base sm:text-[10px]">Giá trị bền vững: Sử dụng gỗ từ nguồn cung ứng bền vững, thân thiện với môi trường.</li>
                                    <li className="lg:text-base sm:text-[10px]">Dịch vụ chuyên nghiệp: Tư vấn tận tâm, thiết kế sáng tạo, bảo hành chu đáo.</li>
                                    <li className="lg:text-base sm:text-[10px]">Đồng hành tiến bước: Kiến tạo không gian sống đẳng cấp, trọn vẹn và khác biệt</li>
                                </ul>
                            </div>
                            <img className="w-full h-full bg-contain" src={imgAbout4} />
                        </div> */}
                    </div>
                </div>
            </div>
            <Social />
            <Footer />
        </div>
    )
}