import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Social from '../Social/Social';
import listNewsData from '../../../data/listNewsData.js';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { LanguageContext } from "../../../context/LanguageContext";

const defineContent = (newItem) => {
    return (
        <div className="w-full">
            <h4 className="my-2 lg:text-lg sm:text-xs font-bold">{newItem.title}</h4>
            <p className="my-2 lg:text-base sm:text-[10px]">{newItem.content[0]}</p>
        </div>
    )
}

const listContent = (newItem) => {
    return (
        <div className="w-full my-5">
            <h4 className="my-2 lg:text-lg sm:text-xs font-bold">{newItem.title}</h4>
            <ul className="list-inside list-disc">
                {
                    newItem.content.map((item) => {
                        return (
                            <li className="lg:text-base sm:text-[10px]">{item}</li>
                        )
                    })
                }
            </ul>
        </div>
    )
}



export default function NewsDetails() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation();
    const itemIndex = location.state;
    const [idNews, setIdNews] = useState(location.state || 1);
    const { language, setLanguage } = useContext(LanguageContext);

    return (
        <div className="w-full h-full">
            <Header />
            <div className="h-full w-full justify-items-center py-5 bg-slate-100">
                <div className='py-5 mt-5 h-full w-[80%] bg-white mx-auto'>
                    <div className='h-full w-full px-6'>
                        <div className='justify-items-center h-full'>
                            {
                                listNewsData.map((item) => {
                                    return (
                                        item.lang === language ?
                                            item.listNewsData.map((news) => {
                                                return (
                                                    news.id === Number(itemIndex) ?
                                                        <div>
                                                            <div className='lg:h-[30%] w-full overflow-hidden sm:h-[20%]'>
                                                                <img className='xl:h-[500px] max-xl:h-[300px] max-md:h-[100px] w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={news.image} />
                                                            </div>
                                                            <div className='h-[60%] w-full'>
                                                                {
                                                                    news.listNewsDetail.map((newItem) => {
                                                                        return (
                                                                            Number(newItem.content.length) === 1 ? defineContent(newItem) : listContent(newItem)
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        : ""
                                                )
                                            })
                                            : ""
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Social />
            <Footer />
        </div >
    )
}