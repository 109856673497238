import socialZalo from "../../../image/social/zalo.png";
import { Link } from "react-router-dom";

export default function Social() {
    return (
        <div className="fixed top-[80%] left-0 right-0">
            <div className="absolute w-[50px] h-[50px] bg-[#2196F3] rounded-full ml-7 top-[80%] animate-ping"></div>
            <Link target="_blank" to='https://zalo.me/0969931907'>
                <div className="w-[40px] h-[40px] bg-[#2196F3] rounded-full justify-items-center absolute ml-[32px] top-[90.5%]">
                    <img src={socialZalo} className="max-w-7 h-full object-contain mx-auto" />
                </div>
            </Link>
        </div >
    )
}