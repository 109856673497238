import { useNavigate } from 'react-router-dom';
import { LanguageContext } from "../../../context/LanguageContext";
import { useContext } from "react";

export default function SuccessDialog() {
    const { language, setLanguage } = useContext(LanguageContext);
    const dialogContent = language === "vi" ? "Gửi thông tin liên hệ thành công" : "The contact information was submitted successfully.";
    const backToContactPage = language === "vi" ? "Trở về trang Liên hệ" : "Back to Contact page";
    const navigate = useNavigate();
    const handleClickSuccessButton = () => {
        navigate("/contact");
    }
    return (
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div class="bg-white px-4 pt-5 sm:px-6">
                            <div class="sm:block justify-items-center">
                                <div class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-lime-100 sm:mx-0 sm:size-10">
                                    <svg class="size-6 text-lime-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:block">
                                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center">
                                    <h3 class="text-base font-semibold text-gray-900" id="modal-title">{dialogContent}</h3>
                                    {/* <div class="mt-2">
                                        <p class="text-sm text-gray-500"></p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button onClick={() => handleClickSuccessButton()} type="button" class="inline-flex w-full justify-center rounded-md bg-lime-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-lime-500">{backToContactPage}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}