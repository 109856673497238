import imgProduct1 from '../../../image/productdetail/001_productDetail.jpg';

import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Social from '../Social/Social';
import listProductDetailName from '../../../data/listProductDetailData.js';

import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function ProductDetail() {

    const location = useLocation();
    const itemIndex = location.state === 1 ? location.state : 1;
    const listImg = listProductDetailName.filter(item => item.id === itemIndex);

    const [imgProduct, setImgProduct] = useState(imgProduct1);

    const goToPrevious = () => {
        let items = listImg.at(0).listImageProductDetail.filter(img => img.image === imgProduct);

        listImg.forEach(imageItem => {
            imageItem.listImageProductDetail.forEach(element => {
                if (Number(items[0].id) > 1 && (element.id === items[0].id)) {
                    setImgProduct(listImg.at(0).listImageProductDetail.at(Number(element.id) - 2).image);
                } else if (Number(items[0].id) === 1 && (element.id === items[0].id)) {
                    setImgProduct(listImg.at(0).listImageProductDetail.at(listImg.at(0).listImageProductDetail.length - 1).image);
                }
            });
        })
    }

    const goToNext = () => {
        let items = listImg.at(0).listImageProductDetail.filter(img => img.image === imgProduct);

        listImg.forEach(imageItem => {
            imageItem.listImageProductDetail.forEach(element => {
                if (Number(items[0].id) < 3 && (element.id === items[0].id)) {
                    setImgProduct(listImg.at(0).listImageProductDetail.at(Number(element.id) - 2).image);
                    setImgProduct(listImg.at(0).listImageProductDetail.at(Number(element.id)).image);
                } else if (Number(items[0].id) === 3 && (element.id === items[0].id)) {
                    setImgProduct(listImg.at(0).listImageProductDetail.at(listImg.at(0).listImageProductDetail.length - 1).image);
                    setImgProduct(listImg.at(0).listImageProductDetail.at(0).image);
                }
            });
        })
    }


    const handleClickImage = (index) => {
        listImg.forEach(product => {
            product.listImageProductDetail.forEach(element => {
                if (element.id === (index + 1)) {
                    setImgProduct(element.image);
                }
            })
        })
    }
    const handleOnWheel = (event) => {
        if (event.deltaY > 0) {
            goToPrevious();
        } else {
            goToNext();
        }
    }

    const handleClickImageDetail = () => {
        const element = document.getElementById("zoomImage");
        element.classList.remove('hidden');
    }

    const handleClose = () => {
        const element = document.getElementById("zoomImage");
        element.classList.add('hidden');
    }

    return (
        <div className="size-full">
            <Header />
            <div className="w-[90%] h-full my-5 mx-auto flex flex-wrap">
                <div className="md:w-1/6 max-md:w-2/6 p-2">
                    {
                        listImg.length === 1 ? listImg.at(0).listImageProductDetail.map((itemImg, index) => {
                            return (
                                < div className='p-2 w-full h-[25%] border-2 border-white hover:shadow-xl hover:border-amber-600' onClick={() => handleClickImage(index)}>
                                    <img alt='image for product' src={itemImg.image} className="h-full w-full bg-cover" />
                                </div>
                            )
                        })
                            : ""
                    }

                </div>
                <div className="md:w-3/6 max-md:w-4/6 p-2">
                    <div className='p-2 w-full h-[50%] border-2 border-white hover:shadow-xl hover:border-amber-600' onClick={() => handleClickImageDetail()}>
                        <img alt='image for product' src={imgProduct} className="h-full w-full bg-contain" />
                    </div>
                </div>
                <div className="md:w-2/6">
                    <div className='p-2 w-full h-full leading-5'>
                        <h1 className='2xl:text-4xl max-lg:text-lg lg:text-xl max-md:text-sm font-bold'>GỖ XẺ 32MM 2C</h1>
                        <ul className='list-disc list-inside'>
                            <li className='2xl:text-3xl max-lg:text-base lg:text-lg max-md:text-xs'>Độ dày: 5/4 (32mm)</li>
                            <li className='2xl:text-3xl max-lg:text-base lg:text-lg max-md:text-xs'>Phân hạng: 2C</li>
                            <li className='2xl:text-3xl max-lg:text-base lg:text-lg max-md:text-xs'>Độ ẩm: 10̊ (+/- 2̊)</li>
                            <li className='2xl:text-3xl max-lg:text-base lg:text-lg max-md:text-xs'>Quy cách: mặt rộng tự do, từ 100mm trở lên. Chiều dài từ 2m trở lên.</li>
                            <li className='2xl:text-3xl max-lg:text-base lg:text-lg max-md:text-xs'>Hàng nhập trực tiếp từ Mỹ, chuyên được dùng làm nội thất cao cấp mang đến tính sang trọng và đăng cấp cho dự án thi công.</li>
                        </ul>
                    </div>
                    <div id="zoomImage" className='z-0 left-0 top-0 fixed opacity-100 w-[100vw] h-[100vh] bg-slate-100 hidden'>
                        <div className="absolute px-5 h-[100%] w-full flex top-0 left-0">
                            <div className="absolute flex w-full justify-end mt-5 pr-10">
                                <button onClick={() => handleClose()} className="bg-white p-2 rounded-full bg-opacity-80 shadow-lg hover:bg-red-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="my-auto flex w-full justify-between">
                                <button onWheelCapture={(event) => handleOnWheel(event)} onClick={goToPrevious} className="bg-white p-2 rounded-full bg-opacity-80 shadow-lg hover:bg-amber-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                                    </svg>
                                </button>
                                <button onClick={goToNext} className="bg-white p-2 rounded-full bg-opacity-80 shadow-lg hover:bg-amber-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className='h-full w-full justify-items-center content-center'>
                            <img alt='image for product' src={imgProduct} className="h-[70%] w-[70%] bg-contain opacity-100 mx-auto" />
                        </div>
                    </div>
                </div>
            </div>
            <Social />
            <Footer />
        </div >
    )
}