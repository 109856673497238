import imgCompany from "../../../image/logo/company_image.jpg";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Social from "../Social/Social";
import { LanguageContext } from "../../../context/LanguageContext";
import { useContext, useEffect } from 'react';
export default function Introduction() {
    const listIntroductionContent = [{
        id: 1,
        lang: "vi",
        content: {
            id: 1,
            opening: "Giới thiệu về ",
            nameCompany: "Công ty TNHH Nông Nghiệp và công nghệ OZONE VIỆT NAM (OZONE VINA COMPANY LIMITED).",
            contentDetail: [
                "Chào mừng bạn đến với OZONE VIỆT NAM, nơi hội tụ sự tinh hoa của kỹ thuật chế biến gỗ truyền thống kết hợp với công nghệ sản xuất hiện đại.",
                "Chúng tôi tự hào là một trong những đơn vị hàng đầu trong lĩnh vực cung cấp các sản phẩm gỗ chất lượng cao từ nguyên liệu gỗ tự nhiên đến các sản phẩm gỗ công nghiệp.",
                "Với hơn 5 năm hình thành và phát triển, OZONE VIỆT NAM đã khẳng định vị thế của mình nhờ cam kết về chất lượng, sự tỉ mỉ trong từng chi tiết và tinh thần sáng tạo không ngừng.",
                "Chúng tôi mang đến những giải pháp tối ưu cho không gian sống và làm việc, giúp khách hàng tận hưởng sự sang trọng, bền vững và thân thiện với môi trường từ những sản phẩm chất lượng của chúng tôi.",
                "Bên cạnh đó chúng tôi luôn cố gắng để góp phần phát triển ngành chế biến gỗ và cải tiến không ngừng để đưa ra các sản phẩm phù hợp với nhu cầu của khách hàng.",
            ],
            ending: "Qua đó chúng tôi hướng đến sự phát triển với phương châm: ",
            slogan: "Phát triển không chỉ là tăng trưởng."
        }
    },
    {
        id: 2,
        lang: "en",
        content: {
            id: 1,
            opening: "About Company ",
            nameCompany: "OZONE Vietnam Agriculture and Technology Co., Ltd. (OZONE VINA COMPANY LIMITED).",
            contentDetail: [
                "Welcome to OZONE VIỆT NAM, where the essence of traditional woodworking craftsmanship meets modern manufacturing technology.",
                "We take pride in being one of the leading providers of high-quality wood products, ranging from natural wood materials to engineered wood solutions.",
                "With over five years of establishment and growth, OZONE VIỆT NAM has solidified its position through a commitment to quality, meticulous attention to detail, and a relentless spirit of innovation.",
                "We offer optimal solutions for living and working spaces, and our high-quality products allow customers to experience luxury, durability, and eco-friendliness.",
                "Additionally, we continuously strive to contribute to the development of the woodworking industry and innovate to create products that meet customer needs.",
            ],
            ending: "Our vision is to grow with the philosophy: ",
            slogan: "Development is not just about growth."
        }
    },
    ];
    const { language, setLanguage } = useContext(LanguageContext);
    useEffect(() => {
    }, [language]);

    return (
        <div className="w-full">
            <Header />
            <div className="w-full h-full bg-slate-50">
                <img className="w-full h-[40vh] bg-contain" src={imgCompany} />
                <div className="w-[80%] m-auto ">

                    {
                        listIntroductionContent.map((item) => {
                            return (
                                item.lang === language ?
                                    <p className="leading-10">
                                        {item.content.opening} <span className="font-bold">{item.content.nameCompany}</span><br />
                                        {
                                            item.content.contentDetail.map((content) => {
                                                return (
                                                    <span>{content}<br /></span>
                                                )
                                            })
                                        }
                                        {item.content.ending} <span className='font-bold text-xl'>{item.content.slogan}</span>
                                    </p> : ""
                            )
                        })
                    }

                    {/* <p className="leading-10">
                        Giới thiệu về <span className="font-bold">Công ty TNHH Nông Nghiệp và công nghệ OZONE VIỆT NAM (OZONE VINA COMPANY LIMITED)</span><br />

                        Chào mừng bạn đến với OZONE VIỆT NAM, nơi hội tụ sự tinh hoa của kỹ thuật chế biến gỗ truyền thống kết hợp với công nghệ sản xuất hiện đại.<br />

                        Chúng tôi tự hào là một trong những đơn vị hàng đầu trong lĩnh vực cung cấp các sản phẩm gỗ chất lượng cao từ nguyên liệu gỗ tự nhiên đến các sản phẩm gỗ công nghiệp.<br />

                        Với hơn 5 năm hình thành và phát triển, OZONE VIỆT NAM đã khẳng định vị thế của mình nhờ cam kết về chất lượng, sự tỉ mỉ trong từng chi tiết và tinh thần sáng tạo không ngừng.<br />

                        Chúng tôi mang đến những giải pháp tối ưu cho không gian sống và làm việc, giúp khách hàng tận hưởng sự sang trọng, bền vững và thân thiện với môi trường từ những sản phẩm chất lượng của chúng tôi.<br />

                        Bên cạnh đó chúng tôi luôn cố gắng để góp phần phát triển ngành chế biến gỗ và cải tiến không ngừng để đưa ra các sản phẩm phù hợp với nhu cầu của khách hàng.<br />

                        Qua đó chúng tôi hướng đến sự phát triển với phương châm: <span className='font-bold text-xl'>Phát triển không chỉ là tăng trưởng.</span><br />
                    </p> */}
                </div >
            </div >
            <Social />
            <Footer />
        </div >
    )
}