import imgProduct1 from '../image/product/001_product_rubber_veneer.jpg';
import imgProduct2 from '../image/product/002_product_dried_sawn_timber.jpg';
import imgProduct3 from '../image/product/003_product_plywood.jpg';
import imgProduct4 from '../image/product/004_product_woodpellets.jpg';
import imgProduct5 from '../image/product/005_product_cashewkernels.jpg';
import imgProduct6 from '../image/product/006_product_pepper.jpg';

const listProductDataMultiLang = [
    {
        id: 1,
        lang: "vi",
        title: "SẢN PHẨM NỔI BẬT",
        listProduct: [{
            id: 1,
            type: "Lâm sản",
            listProductForType: [{
                id: 1,
                image: imgProduct1,
                name: "Ván bóc gỗ cao su"
            },
            {
                id: 2,
                image: imgProduct2,
                name: "Gỗ xẻ sấy"
            },
            {
                id: 3,
                image: imgProduct3,
                name: "Ván dán"
            },
            {
                id: 4,
                image: imgProduct4,
                name: "Viên nén"
            }]
        },
        {
            id: 2,
            type: "Nông sản",
            listProductForType: [{
                id: 1,
                image: imgProduct5,
                name: "Hạt Điều"
            },
            {
                id: 2,
                image: imgProduct6,
                name: "Tiêu"
            }]
        }
        ]
    },
    {
        id: 2,
        lang: "en",
        title: "FEATURED PRODUCT",
        listProduct: [{
            id: 1,
            type: "Forest products",
            listProductForType: [{
                id: 1,
                image: imgProduct1,
                name: "Rubber veneer"
            },
            {
                id: 2,
                image: imgProduct2,
                name: "Rubber timber"
            },
            {
                id: 3,
                image: imgProduct3,
                name: "Plywood"
            },
            {
                id: 4,
                image: imgProduct4,
                name: "Wood pellet"
            }]
        },
        {
            id: 2,
            type: "Agricultural products",
            listProductForType: [{
                id: 1,
                image: imgProduct5,
                name: "Cashew nuts"
            },
            {
                id: 2,
                image: imgProduct6,
                name: "Pepper"
            }]
        }
        ]
    },
];

export default listProductDataMultiLang;