import { imgLogo } from '../../image/logo/company_image.jpg';
import { useContext, useState, useEffect } from 'react';
import { LanguageContext } from "../../context/LanguageContext";
import { Link } from 'react-router-dom';

export default function Footer() {

    const listMenuName = [{
        id: 1,
        lang: "vi",
        menuArr: [{
            id: 1,
            link: "/",
            name: "TRANG CHỦ"
        },
        {
            id: 2,
            link: "/introduction",
            name: "Giới thiệu"
        },
        {
            id: 3,
            link: "/product",
            name: "Sản phẩm"
        },
        {
            id: 4,
            link: "/news",
            name: "Tin tức và sự kiện"
        },
        {
            id: 5,
            link: "/about",
            name: "Về chúng tôi"
        },
        {
            id: 6,
            link: "/contact",
            name: "Liên hệ"
        }]
    },
    {
        id: 2,
        lang: "en",
        menuArr: [{
            id: 1,
            link: "/",
            name: "HOME"
        },
        {
            id: 2,
            link: "/introduction",
            name: "Company overview"
        },
        {
            id: 3,
            link: "/product",
            name: "Production"
        },
        {
            id: 4,
            link: "/news",
            name: "News & Events"
        },
        {
            id: 5,
            link: "/about",
            name: "About us"
        },
        {
            id: 6,
            link: "/contact",
            name: "Contact"
        }]
    },
    ];

    const listMenuContact = [{
        id: 1,
        lang: "vi",
        menuArr: [{
            id: 1,
            name: "THÔNG TIN LIÊN HỆ"
        },
        {
            id: 2,
            name: "Công ty TNHH nông nghiệp và công nghệ OZONE Việt Nam"
        },
        {
            id: 3,
            name: "Địa chỉ: thôn Bình Tân, xã Phước Minh, huyện Bù Gia Mập, tỉnh Bình Phước"
        },
        {
            id: 4,
            name: "Điện thoại: 0969931907 - 0879255588 - 0989266279"
        },
        {
            id: 5,
            name: "Email: ozonevinaglobal@gmail.com"
        },
        {
            id: 5,
            name: "Website: ozonevina.com"
        }]
    },
    {
        id: 2,
        lang: "en",
        menuArr: [{
            id: 1,
            name: "CONTACT INFORMATION"
        },
        {
            id: 2,
            name: "OZONE VINA Company Limited"
        },
        {
            id: 3,
            name: "Address: Bình Tân Hamlet, Phước Minh Village, Bù Gia Mập District, Bình Phước Province"
        },
        {
            id: 4,
            name: "Phone number: 0969931907 - 0879255588 - 0989266279"
        },
        {
            id: 5,
            name: "Email: ozonevinaglobal@gmail.com"
        },
        {
            id: 6,
            name: "Website: ozonevina.com"
        }]
    },
    ];

    const lang = localStorage.getItem("language") || "vi";
    // const [language, setLanguage] = useState(lang);
    const { language, setLanguage } = useContext(LanguageContext);
    useEffect(() => {
    }, [language]);

    return (
        <div className="mt-5">
            <div className='grid md:grid-cols-3 max-md:grid-cols-2 gap-6 w-full px-6'>
                {
                    listMenuName.map((item) => {
                        return (

                            item.lang === language ?
                                <div>
                                    <Link target="_blank" to='/'>
                                        <h1 className='font-semibold mb-5'>{item.menuArr[0].name}</h1>
                                    </Link>
                                    <ul class="list-none divide-y divide-slate-200">
                                        <Link target="_blank" to='/introduction'>
                                            <li className='py-2 cursor-pointer hover:font-semibold'>{item.menuArr[1].name}</li>
                                        </Link>
                                        <Link target="_blank" to='/product'>
                                            <li className='py-2 cursor-pointer hover:font-semibold'>{item.menuArr[2].name}</li>
                                        </Link>
                                        <Link target="_blank" to='/news'>
                                            <li className='py-2 cursor-pointer hover:font-semibold'>{item.menuArr[3].name}</li>
                                        </Link>
                                        <Link target="_blank" to='/about'>
                                            <li className='py-2 cursor-pointer hover:font-semibold'>{item.menuArr[4].name}</li>
                                        </Link>
                                        <Link target="_blank" to='/contact'>
                                            <li className='py-2 cursor-pointer hover:font-semibold'>{item.menuArr[5].name}</li>
                                        </Link>
                                    </ul>
                                </div>
                                : ""

                        )

                    })
                }
                {/* <div>
                    <h1 className='font-semibold mb-5'>TRANG CHỦ</h1>
                    <ul class="list-none divide-y divide-slate-200">
                        <li className='py-2 cursor-pointer hover:font-semibold'>Giới thiêụ</li>
                        <li className='py-2 cursor-pointer hover:font-semibold'>Sản Phẩm</li>
                        <li className='py-2 cursor-pointer hover:font-semibold'>Tin tức và sự kiện</li>
                        <li className='py-2 cursor-pointer hover:font-semibold'>Về chúng tôi</li>
                        <li className='py-2 cursor-pointer hover:font-semibold'>Liên hệ</li>
                    </ul>
                </div> */}
                {
                    listMenuContact.map((item) => {
                        return (

                            item.lang === language ?
                                <div>
                                    <h1 className='text-amber-700 mb-5'>{item.menuArr[0].name}</h1>
                                    <ul class="list-none">
                                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>{item.menuArr[1].name}</li>
                                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>{item.menuArr[2].name}</li>
                                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>{item.menuArr[3].name}</li>
                                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>{item.menuArr[4].name}</li>
                                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>{item.menuArr[5].name}</li>
                                    </ul>
                                </div>
                                : ""

                        )

                    })
                }
                {/* <div>
                    <h1 className='text-amber-700 mb-5'>THÔNG TIN LIÊN HỆ</h1>
                    <ul class="list-none">
                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>Công ty TNHH nông nghiệp và công nghệ OZONE Việt Nam</li>
                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>Địa chỉ: thôn Bình Tân, xã Phước Minh, huyện Bù Gia Mập, tỉnh Bình Phước</li>
                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>Điện thoại: 0969931907 - 0879255588 - 0989266279</li>
                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>Email: ozonevinaglobal@gmail.com</li>
                        <li className='py-2 cursor-pointer text-amber-500 hover:font-semibold'>Website: ozonevina.com</li>
                    </ul>
                </div> */}
                <div className="md:block max-md:hidden justify-items-end">
                    {/* <div className="py-5lg:block md:hidden sm:hidden max-sm:hidden"> */}
                    <div className="py-5 xl:block max-xl:hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3903.46630513161!2d106.84771997505942!3d11.942183088286463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDU2JzMxLjkiTiAxMDbCsDUxJzAxLjEiRQ!5e0!3m2!1sen!2s!4v1735996536061!5m2!1sen!2s"
                            width="400"
                            height="250"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                    {/* <div className="py-5 lg:hidden md:block sm:block max-sm:block"> */}
                    <div className="py-5 xl:hidden max-xl:block max-sm:hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3903.46630513161!2d106.84771997505942!3d11.942183088286463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDU2JzMxLjkiTiAxMDbCsDUxJzAxLjEiRQ!5e0!3m2!1sen!2s!4v1735996536061!5m2!1sen!2s"
                            width="250"
                            height="250"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>

                </div>
            </div>
            <div className="p-5 md:hidden max-md:block max-sm:hidden">
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3903.46630513161!2d106.84771997505942!3d11.942183088286463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDU2JzMxLjkiTiAxMDbCsDUxJzAxLjEiRQ!5e0!3m2!1sen!2s!4v1735996536061!5m2!1sen!2s"
                    width="400"
                    height="250"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
            <div className="p-5 md:hidden max-sm:block sm:hidden">
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3903.46630513161!2d106.84771997505942!3d11.942183088286463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDU2JzMxLjkiTiAxMDbCsDUxJzAxLjEiRQ!5e0!3m2!1sen!2s!4v1735996536061!5m2!1sen!2s"
                    width="250"
                    height="250"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    )
}