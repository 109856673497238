import { useRef, useState, useContext, useEffect } from "react";
import emailjs from "@emailjs/browser";
import socialZalo from "../../../image/social/zalo.png";
import imgCompany from "../../../image/logo/company_image.jpg";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer"
import { LanguageContext } from "../../../context/LanguageContext";
import { useNavigate } from 'react-router-dom';
import Social from "../Social/Social";

export default function Contact() {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [content, setContent] = useState("");
    const [checkFullNameEmpty, setCheckFullNameEmpty] = useState(false);
    const [checkEmailEmpty, setCheckEmailEmpty] = useState(false);
    const [checkPhoneNumberEmpty, setCheckPhoneNumberEmpty] = useState(false);
    const [checkContentEmpty, setCheckContentEmpty] = useState(false);
    const formSubmit = useRef();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        // let check = document.forms["contactForm"]["fullname"].value;
        e.preventDefault();
        if (fullName === '') {
            setCheckFullNameEmpty(true);
        } else {
            setCheckFullNameEmpty(false);
        }

        if (email === '') {
            setCheckEmailEmpty(true);
        } else {
            setCheckEmailEmpty(false);
        }

        if (phoneNumber === '') {
            setCheckPhoneNumberEmpty(true);
        } else {
            setCheckPhoneNumberEmpty(false);
        }

        if (content === '') {
            setCheckContentEmpty(true);
        } else {
            setCheckContentEmpty(false);
        }

        if (fullName !== '' && email !== '' && phoneNumber !== '' && content !== '') {

            emailjs.sendForm('service_io24qa9', 'template_ugtaaur', formSubmit.current, { publicKey: 'AWZiyMgqOF-UUv2RK' })
                .then(
                    () => {
                        navigate('/success');
                    },
                    (error) => {
                        navigate('/error');
                    },
                );
        }
    }

    const handleChangeFullName = (evt) => {
        let fullnameValue = evt.target.value;
        setFullName(fullnameValue);
    }

    const handleChangeEmail = (evt) => {
        let emailValue = evt.target.value;
        setEmail(emailValue);
    }

    const handleChangePhoneNumber = (evt) => {
        let phoneNumberValue = evt.target.value;
        setPhoneNumber(phoneNumberValue);
    }

    const handleChangeContent = (evt) => {
        let contentValue = evt.target.value;
        setContent(contentValue);
    }

    const listContactContent = [{
        id: 1,
        lang: "vi",
        btnName: "Gửi",
        content: {
            id: 1,
            title: "LIÊN HỆ CHÚNG TÔI",
            contentDetail: [{
                lableName: "Họ và tên*:",
                placeHolder: "Nguyễn Văn Anh",
                errorText: "Vui lòng điền Họ và tên"
            },
            {
                lableName: "Email*:",
                placeHolder: "xyz@gmail.com",
                errorText: "Vui lòng điền Email"
            },
            {
                lableName: "Số điện thoại*:",
                placeHolder: "098XXXXXXX",
                errorText: "Vui lòng điền Số diện thoại"
            },
            {
                lableName: "Nội dung*:",
                placeHolder: "Nội dung lời nhắn",
                errorText: "Vui lòng điền Nội dung lời nhắn"
            }],
        }
    },
    {
        id: 2,
        lang: "en",
        btnName: "Submit",
        content: {
            id: 1,
            title: "CONTACT US",
            contentDetail: [{
                lableName: "Full Name*:",
                placeHolder: "Enter your full name...",
                errorText: "Please enter your full name"
            },
            {
                lableName: "Email*:",
                placeHolder: "name@example.com",
                errorText: "Please enter your email"
            },
            {
                lableName: "Phone Number*:",
                placeHolder: "098XXXXXXX",
                errorText: "Please enter your phone number"
            },
            {
                lableName: "Message*:",
                placeHolder: "Enter your message",
                errorText: "Please enter your message"
            }],
        }
    },
    ];

    const listMenuContact = [{
        id: 1,
        lang: "vi",
        companyName: "CÔNG TY TNHH NÔNG NGHIỆP VÀ CÔNG NGHỆ OZONE VIỆT NAM",
        followTitle: "Theo dõi chúng tôi:",
        listInfo: [
            {
                id: 1,
                info: "Địa chỉ: thôn Bình Tân, xã Phước Minh, huyện Bù Gia Mập, tỉnh Bình Phước"
            },
            {
                id: 2,
                info: "Điện thoại: 0969931907 - 0879255588 - 0989266279"
            },
            {
                id: 3,
                info: "Email: ozonevinaglobal@gmail.com"
            },
            {
                id: 4,
                info: "Website: ozonevina.com"
            }]
    },
    {
        id: 2,
        lang: "en",
        companyName: "OZONE Vietnam Agriculture and Technology Co., Ltd. (OZONE VINA COMPANY LIMITED)",
        followTitle: "Follow Us:",
        listInfo: [
            {
                id: 1,
                info: "Address: Bình Tân Hamlet, Phước Minh Village, Bù Gia Mập District, Bình Phước Province"
            },
            {
                id: 2,
                info: "Phone number: 0969931907 - 0879255588 - 0989266279"
            },
            {
                id: 3,
                info: "Email: ozonevinaglobal@gmail.com"
            },
            {
                id: 4,
                info: "Website: ozonevina.com"
            }]
    },
    ];

    const { language, setLanguage } = useContext(LanguageContext);
    useEffect(() => {
    }, [language]);

    return (
        <div className="w-full h-[100%]">
            <Header />
            <img className="w-full h-[50vh] bg-contain" src={imgCompany} />
            <div className="w-full h-full justify-items-center bg-slate-100">

                <div className="grid md:grid-cols-2 max-sm:grid-cols-1 gap-6 w-[80%] h-full py-5 mx-auto">
                    <div className="h-full border-2 border-amber-500 p-5">
                        {
                            listContactContent.map((item) => {
                                return (
                                    item.lang === language ?
                                        <div>
                                            <h1 className="text-amber-500 font-bold">{item.content.title}</h1>
                                            <form ref={formSubmit} name="contactForm" onSubmit={(e) => handleSubmit(e)}>
                                                <div class="mb-6">
                                                    <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{item.content.contentDetail[0].lableName}</label>
                                                    {
                                                        checkFullNameEmpty === false ?
                                                            <div>
                                                                <input onChange={(evt) => handleChangeFullName(evt)} name="fullname" type="text" id="fullname" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={item.content.contentDetail[0].placeHolder} />
                                                            </div> :
                                                            <div>
                                                                <input onChange={(evt) => handleChangeFullName(evt)} name="fullname" type="text" id="fullname" class="bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={item.content.contentDetail[0].placeHolder} />
                                                                <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">{item.content.contentDetail[0].errorText}</label>
                                                            </div>
                                                    }
                                                </div>

                                                <div class="mb-6">
                                                    <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{item.content.contentDetail[1].lableName}</label>
                                                    {
                                                        checkEmailEmpty === false ?
                                                            <div>
                                                                <input onChange={(evt) => handleChangeEmail(evt)} name="email_from" type="text" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={item.content.contentDetail[1].placeHolder} />
                                                            </div> :
                                                            <div>
                                                                <input onChange={(evt) => handleChangeEmail(evt)} name="email_from" type="text" id="email" class="bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={item.content.contentDetail[1].placeHolder} />
                                                                <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">{item.content.contentDetail[1].errorText}</label>
                                                            </div>
                                                    }
                                                </div>

                                                <div class="mb-6">
                                                    <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{item.content.contentDetail[2].lableName}</label>
                                                    {
                                                        checkPhoneNumberEmpty === false ?
                                                            <div>
                                                                <input onChange={(evt) => handleChangePhoneNumber(evt)} name="phonenumber" type="text" id="phonenumber" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={item.content.contentDetail[2].placeHolder} />
                                                            </div> :
                                                            <div>
                                                                <input onChange={(evt) => handleChangePhoneNumber(evt)} name="phonenumber" type="text" id="phonenumber" class="bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={item.content.contentDetail[2].placeHolder} />
                                                                <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">{item.content.contentDetail[2].errorText}</label>
                                                            </div>
                                                    }
                                                </div>

                                                <div class="mb-6">
                                                    <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{item.content.contentDetail[3].lableName}</label>
                                                    {
                                                        checkContentEmpty === false ?
                                                            <div>
                                                                <textarea onChange={(evt) => handleChangeContent(evt)} name="content" type="text" id="content" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " rows="5" placeholder={item.content.contentDetail[3].placeHolder}></textarea>
                                                            </div> :
                                                            <div>
                                                                <textarea onChange={(evt) => handleChangeContent(evt)} name="content" type="text" id="content" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " rows="5" placeholder={item.content.contentDetail[3].placeHolder}></textarea>
                                                                <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">{item.content.contentDetail[3].errorText}</label>
                                                            </div>
                                                    }
                                                </div>
                                                <input type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" value={item.btnName} />
                                            </form>
                                        </div> : ""
                                )
                            })
                        }

                        {/* <h1 className="text-amber-500 font-bold">LIÊN HỆ CHÚNG TÔI</h1>
                        <form ref={formSubmit} name="contactForm" onSubmit={(e) => handleSubmit(e)}>
                            <div class="mb-6">
                                <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Họ và tên*:</label>
                                {
                                    checkFullNameEmpty === false ?
                                        <div>
                                            <input onChange={(evt) => handleChangeFullName(evt)} name="fullname" type="text" id="fullname" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nguyễn Văn A" />
                                        </div> :
                                        <div>
                                            <input onChange={(evt) => handleChangeFullName(evt)} name="fullname" type="text" id="fullname" class="bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nguyễn Văn A" />
                                            <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">Vui lòng điền Họ và tên </label>
                                        </div>
                                }
                            </div>

                            <div class="mb-6">
                                <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email*: </label>
                                {
                                    checkEmailEmpty === false ?
                                        <div>
                                            <input onChange={(evt) => handleChangeEmail(evt)} name="email_from" type="text" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="abc@gmail.com" />
                                        </div> :
                                        <div>
                                            <input onChange={(evt) => handleChangeEmail(evt)} name="email_from" type="text" id="email" class="bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="abc@gmail.com" />
                                            <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">Vui lòng điền Email</label>
                                        </div>
                                }
                            </div>

                            <div class="mb-6">
                                <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Số điện thoại*:</label>
                                {
                                    checkPhoneNumberEmpty === false ?
                                        <div>
                                            <input onChange={(evt) => handleChangePhoneNumber(evt)} name="phonenumber" type="text" id="phonenumber" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="098XXXXXXX" />
                                        </div> :
                                        <div>
                                            <input onChange={(evt) => handleChangePhoneNumber(evt)} name="phonenumber" type="text" id="phonenumber" class="bg-gray-50 border border-red-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="098XXXXXXX" />
                                            <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">Vui lòng điền Số diện thoại</label>
                                        </div>
                                }
                            </div>

                            <div class="mb-6">
                                <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nội dung*:</label>
                                {
                                    checkContentEmpty === false ?
                                        <div>
                                            <textarea onChange={(evt) => handleChangeContent(evt)} name="content" type="text" id="content" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " rows="5" placeholder="Nội dung lời nhắn"></textarea>
                                        </div> :
                                        <div>
                                            <textarea onChange={(evt) => handleChangeContent(evt)} name="content" type="text" id="content" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " rows="5" placeholder="Nội dung lời nhắn"></textarea>
                                            <label for="text" class="block mb-2 text-sm font-medium text-red-500 dark:text-white">Vui lòng điền Nội dung lời nhắn</label>
                                        </div>
                                }
                            </div> */}


                        {/* <div class="mb-6">
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" required />
                            </div>
                            <div class="mb-6">
                                <label for="number" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Số điện thoại</label>
                                <input type="number" id="phonenumber" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Số điện thoại" required />
                            </div>
                            <div class="mb-6">
                                <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nội dung</label>
                                <input type="text" id="content" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nội dung" required />
                            </div> */}
                        {/* <input type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" value="Gửi" /> */}
                        {/* onClick={() => handleSubmit()} */}
                        {/* </form> */}
                    </div>
                    <div className="h-full border-2 border-amber-500 p-5">
                        {
                            listMenuContact.map((item) => {
                                return (
                                    item.lang === language ?
                                        <div>
                                            <h3 className="text-amber-500 font-bold">{item.companyName}</h3>
                                            <ul class="list-inside list-disc">
                                                {
                                                    item.listInfo.map((infoItem) => {
                                                        return (
                                                            <li className='py-2 cursor-pointer'>{infoItem.info}</li>
                                                        )
                                                    })
                                                }
                                                {/* <li className='py-2 cursor-pointer'>Địa chỉ: thôn Bình Tân, xã Phước Minh, huyện Bù Gia Mập, tỉnh Bình Phước</li>
                                                <li className='py-2 cursor-pointer'>Điện thoại: 0969931907 - 0879255588 - 0989266279</li>
                                                <li className='py-2 cursor-pointer'>Email: ozonevinaglobal@gmail.com</li>
                                                <li className='py-2 cursor-pointer'>Website: ozonevina.com</li> */}
                                            </ul>
                                            <h3 className="text-amber-500 font-bold">{item.followTitle}</h3>
                                            <div className="w-[40px]">
                                                <Link target="_blank" to='https://zalo.me/0969931907' className="w-full">
                                                    <div className="w-[40px] h-[40px] bg-[#2196F3] rounded-full justify-items-center">
                                                        <img src={socialZalo} className="max-w-7 h-full object-contain " />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div> : ""
                                )
                            })
                        }

                    </div>
                </div>
            </div>
            <Social />
            <Footer />
        </div>
    )
}