import imgStrongPoint1 from '../image/strong-point/001_strongpoint.png';
import imgStrongPoint2 from '../image/strong-point/002_strongpoint.png';
import imgStrongPoint3 from '../image/strong-point/003_strongpoint.png';
import imgStrongPoint4 from '../image/strong-point/004_strongpoint.png';

const listStrongPointDataMultiLang = [
    {
        id: 1,
        lang: "vi",
        title: "TẠI SAO CHỌN CHÚNG TÔI?",
        content: "Chúng tôi luôn cung cấp sản phẩm bền - đẹp - hấp dẫn, đáp ứng tiêu chuẩn cao và mang đến sự hài lòng cho khách hàng.",
        listStrongPoint: [{
            id: 1,
            title: "Uy Tín Chất lượng",
            image: imgStrongPoint1,
            content: "Hoạt động từ năm 2021, sản phẩm của công ty luôn được đánh giá cao của khách hàng."
        },
        {
            id: 2,
            title: "Giá cả cạnh tranh",
            image: imgStrongPoint2,
            content: "Khách hàng có thể mua sản phẩm của chúng tôi ngay tại nơi sản xuất với giá cả hợp lý."
        },
        {
            id: 3,
            title: "Vận chuyển nhanh chóng",
            image: imgStrongPoint3,
            content: "Hỗ trợ vận chuyển theo yêu cầu của khách hàng."
        },
        {
            id: 4,
            title: "Hỗ trợ tận tâm",
            image: imgStrongPoint4,
            content: "Chúng tôi luôn tư vấn và hỗ trợ khách hàng tận tình, chu đáo."
        },
        ]


    },
    {
        id: 2,
        lang: "en",
        title: "Why Choose Us?",
        content: "We consistently deliver durable, beautiful, and appealing products that meet high standards and ensure customer satisfaction.",
        listStrongPoint: [{
            id: 1,
            title: "Trusted Quality",
            image: imgStrongPoint1,
            content: "Since 2021, our products have consistently received high praise from customers."
        },
        {
            id: 2,
            title: "Competitive Pricing",
            image: imgStrongPoint2,
            content: "Customers can purchase our products directly from the factory at reasonable prices."
        },
        {
            id: 3,
            title: "Quick Shipping",
            image: imgStrongPoint3,
            content: "Delivery support based on customer requests."
        },
        {
            id: 4,
            title: "Dedicated Support",
            image: imgStrongPoint4,
            content: "We are always here to provide thorough and attentive advice and support to our customers."
        },
        ]
    }
];

export default listStrongPointDataMultiLang;