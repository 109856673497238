import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import HomePage from './main/Body/Home/HomePage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Product from './main/Body/Product/Product';
import ProductDetail from './main/Body/Product/ProductDetail';
import Introduction from './main/Body/Intro/Introduction';
import About from './main/Body/Intro/About';
import News from './main/Body/News/News';
import NewsDetails from './main/Body/News/NewsDetail';
import Contact from './main/Body/Contact/Contact';
import SuccessDialog from './main/Body/Contact/SuccessDialog';
import ErrorDialog from './main/Body/Contact/ErrorDialog';
import { LanguageProvider } from './context/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([{
  path: '/',
  element: <HomePage />
},
{
  path: '/product',
  element: <Product />
},
{
  path: '/productdetail',
  element: <ProductDetail />
},
{
  path: '/introduction',
  element: <Introduction />
},
{
  path: '/about',
  element: <About />
},
{
  path: '/news',
  element: <News />
},
{
  path: '/newsdetail',
  element: <NewsDetails />
},
{
  path: '/contact',
  element: <Contact />
},
{
  path: '/success',
  element: <SuccessDialog />
},
{
  path: '/error',
  element: <ErrorDialog />
}
]);

root.render(
  <React.StrictMode>
    {/* <App /> */}
    <LanguageProvider>
      <RouterProvider router={router}>
        <HomePage />
      </RouterProvider>
    </LanguageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
