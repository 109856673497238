import imgProduct1 from '../../../image/product/001_product_old.jpg';
import imgProduct2 from '../../../image/product/002_product_old.jpg';
import imgProduct3 from '../../../image/product/003_product_old.jpg';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Social from '../Social/Social';
import { LanguageContext } from "../../../context/LanguageContext";
import listNewsDataMultiLang from '../../../data/listNewsData.js'
import { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export default function News() {
    const navigate = useNavigate();
    const { language, setLanguage } = useContext(LanguageContext);
    useEffect(() => {
    }, [language]);

    const handleClickNewItem = (index) => {
        navigate("/newsdetail", { state: index });
    }

    return (
        <div className="size-full">
            <Header />
            <div className="size-full justify-items-center py-5 bg-slate-100">
                {
                    listNewsDataMultiLang.map((item) => {
                        return (
                            item.lang === language ?
                                <div className='py-5 mt-5 h-full w-[80%] bg-white mx-auto'>
                                    <div>
                                        <h1 className="2xl:text-5xl max-2xl:text-2xl lg:text-xl sm:text-sm text-center mb-5">{item.title}</h1>
                                    </div>
                                    <div className='grid grid-cols-[auto_auto_auto] md:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-6 h-full w-full px-6'>
                                        {
                                            item.listNewsData.map((newItem, item) => {
                                                return (
                                                    <div onClick={() => handleClickNewItem(item + 1)} className='group justify-items-center h-auto overflow-hidden'>
                                                        <div className='h-[50%] w-full overflow-hidden'>
                                                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={newItem.image} />
                                                        </div>

                                                        <div className="divide-y divide-gray-400 w-full">
                                                            <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">{newItem.listNewsDetail[0].title}</h4>
                                                            <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">{newItem.listNewsDetail[0].content}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* <div className='group justify-items-center h-auto overflow-hidden'>
                                            <div className='h-[50%] w-full overflow-hidden'>
                                                <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct2} />
                                            </div>
                                            <div className="divide-y divide-gray-400 w-full">
                                                <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">XUẤT KHẨU GỖ VÀ SẢN PHẨM NĂM 2025</h4>
                                                <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">Năm 2025, ngành gỗ đặt mục tiêu xuất khẩu đạt 18 tỷ USD. Dựa trên thông kê năm 2024 đạt 15,8 tỷ USD, tăng 20,1% so với năm 2023.</p>
                                            </div>
                                        </div>
                                        <div className='group justify-items-center h-auto overflow-hidden'>
                                            <div className='h-[50%] w-full overflow-hidden'>
                                                <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                                            </div>
                                            <div className="divide-y divide-gray-400 w-full">
                                                <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">VÁN BÓC LÀ GÌ?</h4>
                                                <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">Ván bóc (hay còn gọi là ván lạng) là loại ván được tạo ra bằng cách lạng mỏng gỗ tự nhiên thành từng lớp mỏng có độ dày từ 0.3mm đến 3mm. Các lớp ván này sau đó có thể được dán chồng lên nhau để tạo thành các sản phẩm như ván ép, gỗ dán hoặc sử dụng trực tiếp trong một số ứng dụng nội thất và xây dựng.</p>
                                            </div>
                                        </div>
                                        <div className='group justify-items-center h-auto overflow-hidden'>
                                            <div className='h-[50%] w-full overflow-hidden'>
                                                <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                                            </div>
                                            <div className="divide-y divide-gray-400 size-full">
                                                <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">THÔNG TIN VỀ HẠT ĐIỀU</h4>
                                                <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">Hạt điều là phần nhân ăn được của quả điều, được trồng phổ biến tại các nước nhiệt đới như Việt Nam, Ấn Độ và Châu Phi. Hạt điều có vị bùi, béo, giòn và thường được dùng làm thực phẩm dinh dưỡng hoặc nguyên liệu chế biến món ăn.</p>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>
                                : ""
                        );
                    })
                }

                {/* <div className='py-5 mt-5 h-full w-[80%] bg-white '>
                    <div>
                        <h1 className="2xl:text-5xl max-2xl:text-2xl lg:text-xl sm:text-sm text-center mb-5">HOẠT ĐỘNG VÀ SỰ KIỆN</h1>
                    </div>
                    <div className='grid grid-cols-[auto_auto_auto] md:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-6 h-full w-full px-6'>
                        <div className='group justify-items-center h-auto overflow-hidden'>
                            <div className='h-[50%] w-full overflow-hidden'>
                                <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct1} />
                            </div>
                            <div className="divide-y divide-gray-400 w-full">
                                <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">GỖ GHÉP LÀ GÌ?</h4>
                                <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">Gỗ ghép (hay còn gọi là gỗ ghép thanh) được sản xuất từ loại nguyên liệu chính là gỗ rừng trồng. Những thanh gỗ nhỏ đều được trải qua một quy trình xử lý hấp sấy trên dây chuyền công nghiệp hiện đại và khá nghiêm ngặt để loại bỏ được các thành tố làm hại đến gỗ như mối mọt, ẩm mốc. Sau đó gỗ được cưa, bào, phay, ghép, chà, ép, phủ sơn để tạo nên thành phẩm là gỗ ghép thanh nguyên tấm.</p>
                            </div>
                        </div>
                        <div className='group justify-items-center h-auto overflow-hidden'>
                            <div className='h-[50%] w-full overflow-hidden'>
                                <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct2} />
                            </div>
                            <div className="divide-y divide-gray-400 w-full">
                                <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">XUẤT KHẨU GỖ VÀ SẢN PHẨM NĂM 2025</h4>
                                <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">Năm 2025, ngành gỗ đặt mục tiêu xuất khẩu đạt 18 tỷ USD. Dựa trên thông kê năm 2024 đạt 15,8 tỷ USD, tăng 20,1% so với năm 2023.</p>
                            </div>
                        </div>
                        <div className='group justify-items-center h-auto overflow-hidden'>
                            <div className='h-[50%] w-full overflow-hidden'>
                                <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                            </div>
                            <div className="divide-y divide-gray-400 w-full">
                                <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">VÁN BÓC LÀ GÌ?</h4>
                                <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">Ván bóc (hay còn gọi là ván lạng) là loại ván được tạo ra bằng cách lạng mỏng gỗ tự nhiên thành từng lớp mỏng có độ dày từ 0.3mm đến 3mm. Các lớp ván này sau đó có thể được dán chồng lên nhau để tạo thành các sản phẩm như ván ép, gỗ dán hoặc sử dụng trực tiếp trong một số ứng dụng nội thất và xây dựng.</p>
                            </div>
                        </div>
                        <div className='group justify-items-center h-auto overflow-hidden'>
                            <div className='h-[50%] w-full overflow-hidden'>
                                <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                            </div>
                            <div className="divide-y divide-gray-400 size-full">
                                <h4 className="max-2xl:h-12 2xl:h-18 my-2 2xl:text-3xl lg:text-lg sm:text-xs">THÔNG TIN VỀ HẠT ĐIỀU</h4>
                                <p className="my-2 2xl:text-2xl lg:text-lg sm:text-[12px]">Hạt điều là phần nhân ăn được của quả điều, được trồng phổ biến tại các nước nhiệt đới như Việt Nam, Ấn Độ và Châu Phi. Hạt điều có vị bùi, béo, giòn và thường được dùng làm thực phẩm dinh dưỡng hoặc nguyên liệu chế biến món ăn.</p>
                            </div>

                        </div>
                    </div>
                </div> */}
            </div>
            <Social />
            <Footer />
        </div>
    )
}