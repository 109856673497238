import imgProductDetail1 from '../image/productdetail/001_productDetail.jpg';
import imgProductDetail2 from '../image/productdetail/002_productDetail.jpg';
import imgProductDetail3 from '../image/productdetail/003_productDetail.jpg';

const listProductDetailName = [
    {
        id: 1,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    },
    {
        id: 2,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    },
    {
        id: 3,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    },
    {
        id: 4,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    },
    {
        id: 5,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    },
    {
        id: 6,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    },
    {
        id: 7,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    },
    {
        id: 8,
        listImageProductDetail: [{
            id: 1,
            image: imgProductDetail1
        },
        {
            id: 2,
            image: imgProductDetail2
        },
        {
            id: 3,
            image: imgProductDetail3
        }]
    }

];

export default listProductDetailName;