import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Social from '../Social/Social.js';
import SlideShow from '../Slide/SlideShow';
import { useNavigate } from 'react-router-dom';
import listNewsDataMultiLang from "../../../data/listNewsData.js";
import listProductDataMultiLang from "../../../data/listProductData.js";
import listStrongPointDataMultiLang from "../../../data/listStrongPointData.js";
import { LanguageContext } from "../../../context/LanguageContext";
import { useContext, useEffect } from "react";

export default function HomePage() {
    const navigate = useNavigate();
    const handleClickNews = (id) => {
        console.log("id");
        console.log(id);
        navigate("/newsdetail", { state: id });
    }

    const handleClickProductDetail = (id) => {
        navigate("/productdetail", { state: id });
    }
    const { language, setLanguage } = useContext(LanguageContext);
    const detailButton = language === "en" ? "View details" : "Xem chi tiết";

    useEffect(() => {
    }, [language]);

    return (
        <div className="w-full h-full">
            <Header />
            <SlideShow />
            {
                listStrongPointDataMultiLang.map((item) => {
                    return (
                        item.lang === language ?
                            <div className="bg-banner-img w-full h-full bg-cover p-5 mb-5">
                                <div className="w-full text-center font-family: ui-monospace" >
                                    <h1 className='py-3 text-2xl max-sm:text-xl' >{item.title}</h1>
                                    <p className='mb-3'>{item.content}</p>
                                </div>
                                <div className='grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-8'>
                                    {
                                        item.listStrongPoint.map((point) => {
                                            return (
                                                <div className='justify-items-center'>
                                                    <img src={point.image} className="bg-cover bg-center my-5 mx-auto" />
                                                    <div>
                                                        <h4 className="text-center text-xl my-3">{point.title}</h4>
                                                        <p className="text-justify text-base">{point.content}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <div className='justify-items-center'>
                                        <img src={imgStrongPoint1} className="bg-cover bg-center my-5 mx-auto" />
                                        <div>
                                            <h4 className="text-center text-xl my-3">Uy Tín Chất lượng</h4>
                                            <p className="text-justify text-base">Hoạt động từ năm 2005, sản phẩm của công ty đã có mặt khắp toàn quốc.</p>
                                        </div>
                                    </div>
                                    <div className='justify-items-center'>
                                        <img src={imgStrongPoint2} className="bg-cover bg-center my-5 mx-auto" />
                                        <div>
                                            <h4 className="text-center text-xl my-3">Giá cả cạnh tranh</h4>
                                            <p className="text-justify text-base">Khách hàng có thể mua sản phẩm gỗ ghép của chúng tôi ngay tại xưởng với giá gốc.</p>
                                        </div>
                                    </div>
                                    <div className='justify-items-center'>
                                        <img src={imgStrongPoint3} className="bg-cover bg-center my-5 mx-auto" />
                                        <div>
                                            <h4 className="text-center text-xl my-3">Vận chuyển nhanh chóng</h4>
                                            <p className="text-justify text-base">Hỗ trợ vận chuyển đến tại kho của khách hàng tại Quảng Nam và Thành phố Đà Nẵng.</p>
                                        </div>
                                    </div>
                                    <div className='justify-items-center'>
                                        <img src={imgStrongPoint4} className="bg-cover bg-center my-5 mx-auto" />
                                        <div>
                                            <h4 className="text-center text-xl my-3">Hỗ trợ tận tâm</h4>
                                            <p className="text-justify text-base">Đội ngũ tư vấn và hỗ trợ khách hàng 24/7, tận tình chu đáo.</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            : ""
                    )
                })
            }
            {
                listProductDataMultiLang.map((typeProduct) => {
                    return (

                        typeProduct.lang === language ?
                            <div>
                                <div>
                                    <h1 className="text-2xl max-sm:text-xl text-center mb-5">{typeProduct.title}</h1>
                                </div>
                                <div className='grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-6 w-full px-6'>
                                    {
                                        typeProduct.listProduct[0].listProductForType.map((product, index) => {
                                            if (index < 3) {
                                                return (
                                                    <div className='group justify-items-center min-w-screen group-hover:justify-items-center'>
                                                        <div className='h-[50vh] w-full overflow-hidden'>
                                                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={product.image} />
                                                        </div>
                                                        <h4 className="text-center my-2 text-lg">{product.name}</h4>
                                                        <button className="p-3 bg-amber-500 text-white rounded-full invisible group-hover:visible"><a href='/productdetail'>{detailButton}</a></button>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    {/* <div className='group justify-items-center min-w-screen group-hover:justify-items-center'>
                                        <div className='h-[50vh] w-full overflow-hidden'>
                                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct1} />
                                        </div>
                                        <h4 className="text-center my-2 text-lg">GỖ TRÒN</h4>
                                        <button className="p-3 bg-amber-500 text-white rounded-full invisible group-hover:visible"><a href='/productdetail'>Xem chi tiết</a></button>
                                    </div>
                                    <div className='group justify-items-center'>
                                        <div className='h-[50vh] w-full overflow-hidden'>
                                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct2} />
                                        </div>
                                        <h4 className="text-center my-2 text-lg">GỖ XẺ</h4>
                                        <button className="p-3 bg-amber-500 text-white rounded-full invisible group-hover:visible"><a href='/productdetail'>Xem chi tiết</a></button>
                                    </div>
                                    <div className='group justify-items-center'>
                                        <div className='h-[50vh] w-full overflow-hidden'>
                                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                                        </div>
                                        <h4 className="text-center my-2 text-lg">VÁN PHỦ VANEER</h4>
                                        <button className="p-3 bg-amber-500 text-white rounded-full invisible group-hover:visible"><a href='/productdetail'>Xem chi tiết</a></button>
                                    </div> */}
                                </div>
                            </div>
                            : ""
                    )
                })
            }
            {/* <div>
                <div>
                    <h1 className="text-2xl max-sm:text-xl text-center mb-5">SẢN PHẨM NỔI BẬT</h1>
                </div>
                <div className='grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-6 w-full px-6'>
                    <div className='group justify-items-center min-w-screen group-hover:justify-items-center'>
                        <div className='h-[50vh] w-full overflow-hidden'>
                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct1} />
                        </div>
                        <h4 className="text-center my-2 text-lg">GỖ TRÒN</h4>
                        <button className="p-3 bg-amber-500 text-white rounded-full invisible group-hover:visible"><a href='/productdetail'>Xem chi tiết</a></button>
                    </div>
                    <div className='group justify-items-center'>
                        <div className='h-[50vh] w-full overflow-hidden'>
                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct2} />
                        </div>
                        <h4 className="text-center my-2 text-lg">GỖ XẺ</h4>
                        <button className="p-3 bg-amber-500 text-white rounded-full invisible group-hover:visible"><a href='/productdetail'>Xem chi tiết</a></button>
                    </div>
                    <div className='group justify-items-center'>
                        <div className='h-[50vh] w-full overflow-hidden'>
                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                        </div>
                        <h4 className="text-center my-2 text-lg">VÁN PHỦ VANEER</h4>
                        <button className="p-3 bg-amber-500 text-white rounded-full invisible group-hover:visible"><a href='/productdetail'>Xem chi tiết</a></button>
                    </div>
                </div>
            </div> */}
            {
                listNewsDataMultiLang.map((item) => {
                    return (
                        item.lang === language ?
                            <div className='py-5 mt-5 bg-slate-100'>
                                <div>
                                    <h1 className="sm:text-lg max-sm:text-sm text-center mb-5">{item.title}</h1>
                                </div>

                                <div className='grid xl:grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-6 gap-6 w-full px-6'>

                                    {
                                        item.listNewsData.map((newItem, index) => {
                                            return (
                                                <div className='group justify-items-center' onClick={(id) => handleClickNews(index + 1)}>
                                                    <div className='h-[40vh] w-full overflow-hidden'>
                                                        <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={newItem.image} />
                                                    </div>
                                                    <div className="divide-y divide-gray-400 w-full">
                                                        <h4 className="max-2xl:h-12 my-2 2xl:h-18 lg:text-lg max-lg:text-sm">{newItem.listNewsDetail[0].title}</h4>
                                                        <p className="overflow-hidden h-24 my-2 lg:text-base max-lg:text-sm">{newItem.listNewsDetail[0].content}</p>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>

                            </div>
                            : ""
                    )
                })
            }
            <Footer />
            <Social />
        </div >
    );
};