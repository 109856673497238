import imgNew1 from '../image/news/001_new.jpg';
import imgNew2 from '../image/news/002_new.jpg';
import imgNew3 from '../image/news/003_new.jpg';
import imgNew4 from '../image/news/004_new.jpg';

const listNewsDataMultiLang = [
    {
        id: 1,
        lang: "vi",
        title: "HOẠT ĐỘNG VÀ SỰ KIỆN",
        listNewsData: [
            {
                id: 1,
                image: imgNew1,
                listNewsDetail: [{
                    id: 1,
                    title: 'Gỗ ghép là gì?',
                    content: ['Gỗ ghép (hay còn gọi là gỗ ghép thanh) được sản xuất từ loại nguyên liệu chính là gỗ rừng trồng. Những thanh gỗ nhỏ đều được trải qua một quy trình xử lý hấp sấy trên dây chuyền công nghiệp hiện đại và khá nghiêm ngặt để loại bỏ được các thành tố làm hại đến gỗ như mối mọt, ẩm mốc. Sau đó gỗ được cưa, bào, phay, ghép, chà, ép, phủ sơn để tạo nên thành phẩm là gỗ ghép thanh nguyên tấm.Hiện nay, khu vực có sản lượng gỗ ghép lớn nhất là ở Châu Âu với những cánh rừng nguyên sinh bạt ngàn, sau đó là Châu Á và Châu Mỹ. Ngoài ra tại Châu Á thì Nhật Bản được mệnh danh là đất nước có trình độ ghép gỗ xuất sắc nhất, chỉ cần tạo mộng mà không phải dùng tới keo kết dính.']
                },
                {
                    id: 2,
                    title: 'Ưu điểm của gỗ ghép',
                    content: ['Gỗ có sự đa dạng về mẫu mã, bề mặt đã được xử lý tốt nên có độ bền màu cao, có khả năng chịu xước và chịu va đập tốt.',
                        'Không bị mối mọt, cong vênh như nhiều loại gỗ khác',
                        'Giá thành của gỗ tự nhiên ghép thanh thấp hơn gỗ tự nhiên nguyên khối từ 20 - 30%.',
                        'Độ bền của loại gỗ này không hề thua kém độ bền của gỗ tự nhiên nguyên khối nếu như các đơn vị sản xuất sử dụng các loại keo dán đảm bảo chất lượng.',
                        'Vật liệu chủ yếu lấy từ rừng trồng nên có thể giải quyết đươc vấn đề cạn kiệt của gỗ tự nhiên.'
                    ]
                },
                {
                    id: 3,
                    title: 'Nhược điểm của gỗ ghép',
                    content: ['Gỗ ghép thanh công nghiệp này là trong cùng một tấm gỗ thì sự đồng đều về màu sắc và đường vân không cao.']
                },
                {
                    id: 4,
                    title: 'Ứng dụng của gỗ ghép trong thực tế',
                    content: ['Thiết kế nội thất shop bán hàng, showroom trưng bày.',
                        'Sản xuất đồ nội thất gia đình, văn phòng',
                        'Làm đồ nội thất ngoài trời (gỗ ghép có khả năng chống ẩm, mối mọt cao)',
                        'Làm kệ sách, kệ treo tường',
                        'Làm sàn gỗ gia đình và văn phòng',
                        'Làm khung tranh',
                        'Làm đồ thủ công mỹ nghệ'
                    ]
                }
                ]
            },
            {
                id: 2,
                image: imgNew2,
                listNewsDetail: [{
                    id: 1,
                    title: 'Xuất khẩu gỗ và sản phẩm gỗ năm 2025',
                    content: ['Năm 2025, ngành gỗ đặt mục tiêu xuất khẩu đạt 18 tỷ USD. Dựa trên thông kê năm 2024 đạt 15,8 tỷ USD, tăng 20,1% so với năm 2023. Nhờ sự phục hồi kinh tế tại các thị trường lớn nên ngành gỗ Việt Nam đã đạt được kết quả xuất khẩu ấn tượng.']
                },
                {
                    id: 2,
                    title: 'Các điểm đến nổi bật cho xuất khẩu gỗ và sản phẩm gỗ năm 2025',
                    content: ['Giá trị Mỹ xuất khẩu gỗ và sản phẩm gỗ đạt 9,1 tỷ USD đạt 56% tổng kim ngạch xuất khảu vẫn chiếm vị trí số 1 cho xuất khẩu ngành gỗ của Việt Nam.',
                        'Nhật Bản và Trung Quốc là hai thị trường lớn tiếp theo với thị phần tương ứng 11,7% và 9,8%.',
                        'Hàn Quốc, EU và các thị trường khác là thị trường tiềm năng để xuất khẩu.',
                        'Các sản phẩm xuất khẩu đa dạng: đồ gỗ (sản phẩm khác ghế ngồi), viên nén, dăm gỗ, gỗ dán, đặc biệt sản phẩm ghế ngồi đang rất được ưa thích.',
                        'Hiệp định EVFTA (Hiệp định thương mại tự do Việt Nam - EU) đưa ra ưu đãi về thuế quan giúp tăng cơ hội xuất khẩu gỗ và sản phẩm gỗ'
                    ]
                },
                {
                    id: 3,
                    title: 'Thách thức và khó khăn trong việc xuất khẩu',
                    content: ['Áp lực cạnh tranh từ các nhà cung cấp khác.',
                        'Nhu cầu tiêu dùng lại với sự phục hồi kinh tế toàn cầu.',
                        'Năng lực cạnh tranh của các doanh nghiệp.',
                        'Chính sách xuất & nhập khẩu, thương mại.'
                    ]
                },
                {
                    id: 4,
                    title: 'Cải tiến để tồn tại và phát triển',
                    content: ['Nhiều doanh nghiệp đã ứng dụng công nghệ mới giúp giảm chi phí và giảm giá thành sản phẩm.',
                        'Cơ sở hạ tầng ngày càng phát triển, tăng số lượng và chất lượng sản phẩm',
                    ]
                }
                ]
            },
            {
                id: 3,
                image: imgNew3,
                listNewsDetail: [{
                    id: 1,
                    title: 'Ván bóc là gì?',
                    content: ['Ván bóc (hay còn gọi là ván lạng) là loại ván được tạo ra bằng cách lạng mỏng gỗ tự nhiên thành từng lớp mỏng có độ dày từ 0.3mm đến 3mm. Các lớp ván này sau đó có thể được dán chồng lên nhau để tạo thành các sản phẩm như ván ép, gỗ dán hoặc sử dụng trực tiếp trong một số ứng dụng nội thất và xây dựng.']
                },
                {
                    id: 2,
                    title: 'Các điểm nổi bật của ván bóc',
                    content: ['So với gỗ tự nhiên nguyên khối, ván bóc có giá thành thấp hơn nhiều do tận dụng được nguồn nguyên liệu gỗ rừng trồng như keo, bạch đàn, cao su...',
                        'Vì gỗ được lạng thành từng lớp mỏng nên có thể tận dụng tối đa cây gỗ, hạn chế lãng phí',
                        'Khi được dán ép thành ván ép hoặc gỗ dán, ván bóc có độ bền tốt, chịu lực khá tốt',
                        'Được sử dụng nhiều trong sản xuất nội thất, xây dựng, làm tấm lót, ván ép, pallet gỗ, bao bì...',
                        'Có thể cắt, uốn cong và ghép lại dễ dàng để tạo ra các sản phẩm theo yêu cầu.'
                    ]
                },
                {
                    id: 3,
                    title: 'Một số thách thức khi sử dụng ván bóc: ',
                    content: ['Nếu không xử lý tốt, ván bóc dễ bị cong vênh, nứt hoặc mối mọt khi tiếp xúc với môi trường ẩm ướt.',
                        'So với gỗ nguyên khối, ván bóc có bề mặt không đẹp bằng và thường cần dán veneer hoặc sơn phủ để tăng tính thẩm mỹ.'
                    ]
                },
                {
                    id: 4,
                    title: 'Ứng dụng ván bóc trong cuộc sống',
                    content: ['Ván bóc được dán chồng lên nhau và ép bằng keo để tạo thành ván ép, dùng trong sản xuất bàn, ghế, tủ, giường...',
                        'Một số loại ván bóc chất lượng cao có thể dùng để làm sàn gỗ công nghiệp hoặc ốp trần nhà.',
                        'Các nhà máy sản xuất ván ép, ván dăm đều sử dụng ván bóc làm nguyên liệu chính.',
                        'Một số loại đàn guitar, violin có thể sử dụng ván bóc để tạo lớp mặt ngoài.',
                        'Bảng gỗ, mô hình học tập có thể làm từ ván bóc.'
                    ]
                }
                ]
            },
            {
                id: 4,
                image: imgNew4,
                listNewsDetail: [{
                    id: 1,
                    title: 'Hạt điều là gì?',
                    content: ['Hạt điều là phần nhân ăn được của quả điều, được trồng phổ biến tại các nước nhiệt đới như Việt Nam, Ấn Độ và Châu Phi. Hạt điều có vị bùi, béo, giòn và thường được dùng làm thực phẩm dinh dưỡng hoặc nguyên liệu chế biến món ăn.']
                },
                {
                    id: 2,
                    title: 'Ưu điểm của hạt điều',
                    content: ['Chứa nhiều protein, chất béo lành mạnh, vitamin và khoáng chất cần thiết cho cơ thể.',
                        'Hạt điều có vị ngọt bùi, béo nhẹ, phù hợp với nhiều món ăn và dễ kết hợp trong chế độ ăn uống.',
                        'Nếu bảo quản đúng cách, hạt điều có thể giữ chất lượng trong nhiều tháng mà không bị hư hỏng.',
                        'Có thể ăn trực tiếp, rang muối, làm sữa hạt, chế biến trong món ăn hoặc làm bánh kẹo.',
                        'Hạt điều là mặt hàng xuất khẩu có giá trị, đặc biệt là ở Việt Nam – một trong những nước xuất khẩu hạt điều hàng đầu thế giới.'
                    ]
                },
                {
                    id: 3,
                    title: 'Một số khó khăn khi sử dụng hạt điều: ',
                    content: ['Nếu không xử lý tốt, ván bóc dễ bị cong vênh, nứt hoặc mối mọt khi tiếp xúc với môi trường ẩm ướt.',
                        'Một số người có thể bị dị ứng với hạt điều, gây ngứa, sưng hoặc khó thở.',
                        'Hạt điều chứa nhiều dầu nên cần để ở nơi khô hoặc kín gió.'
                    ]
                },
                {
                    id: 4,
                    title: 'Ảnh hưởng tốt khi sử dụng hạt điều cho cơ thể',
                    content: ['Hạt điều cũng có magie và kali, hỗ trợ điều hòa huyết áp.',
                        'Hạt điều giàu magie, kẽm và vitamin B, giúp tăng cường trí nhớ và giảm căng thẳng.',
                        'Chứa nhiều protein và magie, giúp duy trì và phát triển cơ bắp, hỗ trợ xương chắc khỏe.',
                        'Chất béo lành mạnh trong hạt điều giúp no lâu, hạn chế cảm giác thèm ăn.',
                        'Hạt điều giàu đồng, giúp sản xuất melanin, giúp da và tóc khỏe mạnh.',
                        'Hạt điều chứa kẽm, giúp cơ thể tăng sức đề kháng và chống lại bệnh tật.'
                    ]
                }
                ]
            }
        ]
    },
    {
        id: 2,
        lang: "en",
        title: "ACTIVITIES AND EVENTS",
        listNewsData: [
            {
                id: 1,
                image: imgNew1,
                listNewsDetail: [{
                    id: 1,
                    title: 'What is laminated wood?',
                    content: ['Laminated wood (also known as finger-jointed wood) is produced primarily from plantation-grown timber. Small wooden slats undergo a strict and modern industrial processing system, including steaming and drying, to eliminate harmful factors such as termites and mold. The wood is then cut, planed, milled, joined, sanded, pressed, and coated with paint to form a complete laminated wood panel. Currently, the largest production of laminated wood is in Europe, where vast primeval forests exist, followed by Asia and the Americas. Additionally, in Asia, Japan is renowned for its exceptional wood joinery techniques, where wood pieces are interlocked using traditional joinery methods without adhesive.']
                },
                {
                    id: 2,
                    title: 'Advantages of Laminated Wood',
                    content: ['The Laminated Wood comes in a variety of designs, with well-treated surfaces that ensure high color durability, scratch resistance, and strong impact resistance.',
                        'It is resistant to termites and warping, unlike many other types of wood.',
                        'The price of laminated natural wood is 20-30% lower than that of solid natural wood.',
                        'The durability of this type of wood is not inferior to that of solid natural wood, provided that manufacturers use high-quality adhesives.',
                        'The material is mainly sourced from plantation forests, helping to address the depletion of natural wood resources.'
                    ]
                },
                {
                    id: 3,
                    title: 'Disadvantages of Laminated Wood',
                    content: ['For industrial laminated wood, the uniformity of color and grain patterns within the same panel is not high.']
                },
                {
                    id: 4,
                    title: 'Applications of Laminated Wood in Practice',
                    content: ['Interior design for retail shops and display showrooms.',
                        'Manufacturing household and office furniture.',
                        'Making outdoor furniture (laminated wood has high moisture and termite resistance).',
                        'Making bookshelves and wall-mounted shelves.',
                        'Making wooden flooring for homes and offices.',
                        'Making picture frames.',
                        'Making handicrafts.'
                    ]
                }
                ]
            },
            {
                id: 2,
                image: imgNew2,
                listNewsDetail: [{
                    id: 1,
                    title: 'Export of Wood and Wood Products in 2025',
                    content: ["In 2025, the wood industry aims to reach an export value of 18 billion USD. Based on statistics from 2024, exports reached 15.8 billion USD, an increase of 20.1% compared to 2023. Thanks to the economic recovery in major markets, Vietnam's wood industry has achieved impressive export results."]
                },
                {
                    id: 2,
                    title: 'Key Destinations for Wood and Wood Product Exports in 2025',
                    content: ["The export value of wood and wood products to the U.S. reached 9.1 billion USD, accounting for 56% of total export turnover, maintaining its position as the top export market for Vietnam's wood industry.",
                        'Japan and China are the next two largest markets, with respective market shares of 11.7% and 9.8%.',
                        'South Korea, the EU, and other markets are potential export destinations.',
                        'A diverse range of export products includes wooden furniture (excluding seating), wood pellets, wood chips, and plywood. Notably, seating products are highly favored.',
                        'The EVFTA (Vietnam-EU Free Trade Agreement) provides tariff incentives, increasing export opportunities for wood and wood products.'
                    ]
                },
                {
                    id: 3,
                    title: 'Challenges and Difficulties in Exporting',
                    content: ['Competitive pressure from other suppliers.',
                        'Consumer demand aligns with global economic recovery.',
                        'The competitiveness of enterprises.',
                        'Export and import policies and trade regulations.'
                    ]
                },
                {
                    id: 4,
                    title: 'Innovation for survival and growth',
                    content: ['Many businesses have adopted new technology to reduce costs and lower product prices.',
                        'Infrastructure is increasingly developing, enhancing both the quantity and quality of products.',
                    ]
                }
                ]
            },
            {
                id: 3,
                image: imgNew3,
                listNewsDetail: [{
                    id: 1,
                    title: 'What is veneer?',
                    content: ["Veneer (also known as peeled wood) is a type of board created by peeling natural wood into thin layers, with a thickness ranging from 0.3mm to 3mm. These layers can then be glued together to form products such as plywood and laminated wood or used directly in certain interior and construction applications."]
                },
                {
                    id: 2,
                    title: 'Key Highlights of Veneer',
                    content: ['Compared to solid natural wood, veneer is much more affordable as it utilizes plantation timber sources such as acacia, eucalyptus, and rubberwood....',
                        'Since the wood is peeled into thin layers, it maximizes the use of the tree and minimizes waste.',
                        'When laminated into plywood or laminated wood, veneer has good durability and decent load-bearing capacity.',
                        'Widely used in furniture manufacturing, construction, lining boards, plywood, wooden pallets, and packaging...',
                        'It can be easily cut, bent, and joined to create customized products.'
                    ]
                },
                {
                    id: 3,
                    title: 'Some Challenges of Using Veneer: ',
                    content: ['If not properly treated, veneer can warp, crack, or be susceptible to termites when exposed to humid environments.',
                        'Compared to solid wood, veneer has a less attractive surface and often requires a veneer layer or paint coating to enhance its aesthetics.'
                    ]
                },
                {
                    id: 4,
                    title: 'Applications of Veneer in life',
                    content: ['Veneer is layered and bonded with adhesive to create plywood, which is used in the production of tables, chairs, cabinets, and beds....',
                        'Some high-quality veneer types can be used for engineered wood flooring or ceiling paneling.',
                        'Plywood and particleboard factories use veneer as the primary raw material.',
                        'Some guitars and violins can use veneer to create the outer layer.',
                        'Wooden boards, learning models can be made from peeled boards.'
                    ]
                }
                ]
            },
            {
                id: 4,
                image: imgNew4,
                listNewsDetail: [{
                    id: 1,
                    title: 'What is cashew nut?',
                    content: ['Cashew nuts are the edible kernels of the cashew fruit, commonly grown in tropical countries such as Vietnam, India, and Africa. Cashew nuts have a rich, buttery, and crunchy taste and are often used as a nutritious snack or ingredient in various dishes.']
                },
                {
                    id: 2,
                    title: 'Advantages of Cashew Nuts',
                    content: ['They are rich in protein, healthy fats, vitamins, and minerals essential for the body.',
                        'Cashew nuts have a sweet, nutty, and mildly creamy taste, making them suitable for a variety of dishes and easy to incorporate into a diet.',
                        'If stored properly, cashew nuts can maintain their quality for several months without spoiling.',
                        'They can be eaten directly, roasted with salt, made into nut milk, used in dishes, or incorporated into confectionery.',
                        'Cashew nuts are a valuable export item, especially in Vietnam, one of the leading cashew nut exporters in the world.'
                    ]
                },
                {
                    id: 3,
                    title: 'Some Challenges of Using Cashew Nuts: ',
                    content: ['If not properly treated, veneer can warp, crack, or be susceptible to termites when exposed to humid environments.',
                        'Some people may be allergic to cashew nuts, causing itching, swelling, or difficulty breathing.',
                        'Cashew nuts contain a lot of oil, so they should be stored in a dry place or an airtight container.'
                    ]
                },
                {
                    id: 4,
                    title: 'The positive effects of consuming cashew nuts on the body.',
                    content: ['Cashew nuts also contain magnesium and potassium, which help regulate blood pressure.',
                        'Cashew nuts are rich in magnesium, zinc, and B vitamins, which help enhance memory and reduce stress.',
                        'Rich in protein and magnesium, cashew nuts help maintain and develop muscles while supporting strong bones.',
                        'The healthy fats in cashew nuts help keep you full for longer and reduce cravings.',
                        'Cashew nuts are rich in copper, which supports melanin production, promoting healthy skin and hair.',
                        'Cashew nuts contain zinc, which helps boost the immune system and fight diseases.'
                    ]
                }
                ]
            }
        ]
    }


];

export default listNewsDataMultiLang;