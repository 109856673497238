import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import { GlobeAltIcon } from "@heroicons/react/24/outline"
import { LanguageContext } from "../../context/LanguageContext";
const handleClickMenuItem = () => {
    // debugger

    document.querySelectorAll("#menuName a").forEach(item => {
        item.classList.remove("border-amber-600");
        item.classList.remove("text-amber-600");
        // item.addEventListener("click", function () {
        //     document.querySelectorAll("#menuName a").forEach(a => {
        //         console.log("Check1");
        //         a.classList.remove("border-amber-600");
        //         a.classList.remove("text-amber-600");
        //     });
        //     console.log("Check2");
        //     this.classList.add("border-amber-600");
        //     this.classList.add("text-amber-600");
        // })
    });
    // this.className.add("border-amber-600");
    // this.className.add("text-amber-600");
}

// const handleClick = () => {
//     debugger
//     document.querySelectorAll("#menuName a").forEach(item => {
//         item.addEventListener("click", function () {
//             document.querySelectorAll("#menuName a").forEach(a => {
//                 console.log("Check1");
//                 a.classList.remove("border-amber-600");
//                 a.classList.remove("text-amber-600");
//             });
//             console.log("Check2");
//             this.classList.add("border-amber-600");
//             this.classList.add("text-amber-600");
//         })
//     });
// }

export default function Header() {
    const listMenuName = [{
        id: 1,
        lang: "vi",
        menuArr: [{
            id: 1,
            link: "/",
            name: "TRANG CHỦ"
        },
        {
            id: 2,
            link: "/introduction",
            name: "GIỚI THIỆU"
        },
        {
            id: 3,
            link: "/product",
            name: "SẢN PHẨM"
        },
        {
            id: 4,
            link: "/news",
            name: "TIN TỨC VÀ SỰ KIỆN"
        },
        {
            id: 5,
            link: "/about",
            name: "VỀ CHÚNG TÔI"
        },
        {
            id: 6,
            link: "/contact",
            name: "LIÊN HỆ"
        }]
    },
    {
        id: 2,
        lang: "en",
        menuArr: [{
            id: 1,
            link: "/",
            name: "HOME"
        },
        {
            id: 2,
            link: "/introduction",
            name: "COMPANY OVERVIEW"
        },
        {
            id: 3,
            link: "/product",
            name: "PRODUCTION"
        },
        {
            id: 4,
            link: "/news",
            name: "NEWS & EVENTS"
        },
        {
            id: 5,
            link: "/about",
            name: "ABOUT US"
        },
        {
            id: 6,
            link: "/contact",
            name: "CONTACT"
        }]
    },
    ];

    const lang = localStorage.getItem("language") || "vi";
    const { language, setLanguage } = useContext(LanguageContext);
    // const [language, setLanguage] = useState(lang);
    const [isSelectedEN, setIsSelectedEN] = useState(language === "en" ? true : false);
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState("translate-x-full");

    useEffect(() => {
        localStorage.setItem("language", language);
        const timer = setTimeout(() => {
            setPosition("translate-x-0");
        }, 100);
        return () => clearTimeout(timer);
    }, [language, position]);

    const handleChangeLanguage = (env) => {
        localStorage.setItem("language", env.target.value);
        setLanguage(env.target.value);
        if (env.target.value === "en") {
            setIsSelectedEN(true);
        } else {
            setIsSelectedEN(false);
        }
    }

    const handleClickNavbar = () => {
        setIsOpen(true);
        setPosition("translate-x-full");
    }

    const handleCloseBtn = () => {
        setIsOpen(false);
        setPosition("translate-x-full");
    }

    return (

        isOpen ?
            <div className="w-[100] h-[50vh] justify-items-end">
                <div className="relative h-[100%] w-[40%]">
                    <nav id="menuName" className={`absolute z-10 flex flex-col h-20 justify-around size-full transition-transform duration-1000 ease-out ${position}`}>
                        <div onClick={() => handleCloseBtn()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>

                        </div>
                        {
                            listMenuName.map((item) => {
                                return (

                                    item.lang === language ? item.menuArr.map((menu) => {
                                        return (
                                            <Link onClick={() => handleClickMenuItem()} to={menu.link} className="rounded-md border-2 border-none hover:border-amber-600 hover:text-amber-600 px-5 py-2 my-2">
                                                <div className="text-xs content-center h-full">{menu.name}</div>
                                            </Link>
                                        )
                                    }) : ""

                                )

                            })
                        }
                        <div onClick={() => handleClickMenuItem()} className="rounded-md border-2 border-none hover:border-amber-600 hover:text-amber-600 px-5 py-2 my-2 flex flex-row cursor-pointer items-center">

                            <select className="sm:text-sm max-sm:text-xs bg-inherit" name="language" id="language" onChange={(evn) => handleChangeLanguage(evn)}>
                                <option className="sm:text-sm max-sm:text-xs" value="vi">VietNam</option>
                                <option className="sm:text-sm max-sm:text-xs" value="en" selected={isSelectedEN}>English</option>
                            </select>

                        </div>
                    </nav>
                </div>

            </div> :
            <div className="flex w-full sm:text-[6px] md:text-[8px] lg:text-base max-md:justify-end">
                <div className="md:hidden max-md:w-1/6 my-5" onClick={() => handleClickNavbar()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>

                </div>
                <div className="w-1/6 max-md:hidden"></div>
                <div className="w-5/6 max-md:hidden font-bold bg-100 leading-10">
                    <nav id="menuName" className="flex h-20 justify-around">
                        {
                            listMenuName.map((item) => {
                                return (

                                    item.lang === language ? item.menuArr.map((menu) => {
                                        return (
                                            <Link onClick={() => handleClickMenuItem()} to={menu.link} className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2 text-center">
                                                <div className="sm:text-sm max-sm:text-xs content-center h-full">{menu.name}</div>
                                            </Link>
                                        )
                                    }) : ""

                                )

                            })
                        }
                        {/* <div className="flex flex-col"> */}
                        <div onClick={() => handleClickMenuItem()} className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2 flex flex-row cursor-pointer items-center">
                            {/* <div className="basis-1/3 mr-2 cursor-pointer">
                                <GlobeAltIcon className="size-6" />
                            </div>
                            <div className="text-base content-center h-full basis-2/3 cursor-pointer">{
                                lang === "vi" ? "VietNam" : "English"
                            }</div> */}
                            <select name="language" id="language" onChange={(evn) => handleChangeLanguage(evn)}>
                                <option className="sm:text-sm max-sm:text-xs" value="vi">VietNam</option>
                                <option className="sm:text-sm max-sm:text-xs" value="en" selected={isSelectedEN}>English</option>
                            </select>

                        </div>
                        {/* <div className="absolute top-5">AAAAAAAAA</div> */}
                        {/* </div> */}


                        {/* <Link onClick={() => handleClickMenuItem()} to="/" className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2">
                        <div className="text-base content-center h-full">TRANG CHỦ</div>
                    </Link>
                    <Link onClick={() => handleClickMenuItem()} to={link} className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2">
                        <div className="text-base content-center h-full">GIỚI THIỆU</div>
                    </Link>
                    <Link onClick={() => handleClickMenuItem()} to="/product" className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2">
                        <div className="text-base content-center h-full">SẢN PHẨM</div>
                    </Link>
                    <Link onClick={() => handleClickMenuItem()} to="/news" className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2">
                        <div className="text-base content-center h-full">TIN TỨC VÀ SỰ KIỆN</div>
                    </Link>
                    <Link onClick={() => handleClickMenuItem()} to="/about" className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2">
                        <div className="text-base content-center h-full">VỀ CHÚNG TÔI</div>
                    </Link>
                    <Link onClick={() => handleClickMenuItem()} to="/contact" className="rounded-md border-2 border-white hover:border-amber-600 hover:text-amber-600 px-2 py-2 my-2">
                        <div className="text-base content-center h-full">LIÊN HỆ</div>
                    </Link> */}
                    </nav>
                </div >
            </div>
    )
}