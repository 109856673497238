import imgSlide1 from "../../../image/slide/image1.jpg"
import imgSlide2 from "../../../image/slide/image2.jpg"
import imgSlide3 from "../../../image/slide/image3.jpg"
import React, { useEffect, useState } from "react";

const images = [
    imgSlide1,
    imgSlide2,
    imgSlide3,
];

export default function SlideShow() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <section>
            <div className="relative">
                <ul id="slider">
                    <li className="h-[40vh] relative">
                        <img src={images[currentIndex]} className="w-full h-full object-cover"></img>
                    </li>
                </ul>
                <div className="absolute z-0 px-5 h-[40vh] w-full flex top-0 left-0">
                    <div className="my-auto flex w-full justify-between">
                        <button onClick={goToPrevious} className="bg-white p-2 rounded-full bg-opacity-80 shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                            </svg>
                        </button>
                        <button onClick={goToNext} className="bg-white p-2 rounded-full bg-opacity-80 shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </section>

    );
}