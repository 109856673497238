import Header from '../../Header/Header';
import Social from '../Social/Social';
import Footer from '../../Footer/Footer';
import imgProduct1 from '../../../image/product/001_product_old.jpg';
import imgProduct2 from '../../../image/product/002_product_old.jpg';
import imgProduct3 from '../../../image/product/003_product_old.jpg';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

export default function Product() {
    const navigate = useNavigate();
    const listProductName = [
        {
            id: '1',
            typeName: 'VÁN GHÉP THANH',
            listProductByType: [{
                id: '1',
                name: 'GỖ KEO GHÉP THANH',
                img: imgProduct1
            },
            {
                id: '2',
                name: 'GỖ GHÉP XOAN',
                img: imgProduct2
            },
            {
                id: '3',
                name: 'GỖ TRÀM GHÉP THANH',
                img: imgProduct3
            },
            {
                id: '4',
                name: 'GỖ THÔNG GHÉP THANH',
                img: imgProduct1
            }]
        },
        {
            id: '2',
            typeName: 'VÁN ÉP CÔNG NGHIỆP',
            listProductByType: [{
                id: '5',
                name: 'VÁN ÉP NỘI THẤT',
                img: imgProduct3
            },
            {
                id: '6',
                name: 'VÁN ÉP BAO BÌ',
                img: imgProduct2
            },
            {
                id: '7',
                name: 'VÁN ÉP PHỦ PHIM (FILM)',
                img: imgProduct1
            },
            {
                id: '8',
                name: 'VÁN ÉP (FLYWOOD)',
                img: imgProduct1
            }]
        }

    ];

    const [items, setItems] = useState(listProductName);

    const handleClick = (index) => {

        if (index !== 0) {
            let arr = [];
            arr.push(listProductName[index - 1]);
            setItems(arr);
        } else {
            setItems(listProductName);
        }
        const typeProElement = document.getElementById('containerTypeProduct');
        let listElement = typeProElement.getElementsByTagName('button');
        listElement = Object.values(listElement);
        if (Array.isArray(listElement)) {
            listElement.forEach(btnElement => {
                btnElement.classList.remove('bg-amber-500');
            });
        }

        const element = document.getElementById('btnTypeProduct' + index);
        element.classList.add('bg-amber-500');
    }

    const handleClickProductDetail = (index) => {
        navigate("/productdetail", { state: index });
    }

    return (
        <div className="w-full h-full">
            <Header />
            <div className="w-[100vw] h-[100%] bg-slate-100 py-5">

                {/* <div className="w-[90%] h-full bg-white justify-items-center"> */}
                <div id='containerTypeProduct' className="w-[90%] mx-auto flex flex-row flex-wrap gap-4 mb-5">
                    <button id="btnTypeProduct0" className="flex-1 max-sm:text-[10px] text-xs p-3 bg-amber-500 border border-rose-300 rounded" onClick={() => handleClick(0)}>TẤT CẢ</button>
                    {
                        listProductName.map((productName, index) => {
                            return (
                                < button id={"btnTypeProduct" + (index + 1)} className="flex-1 max-sm:text-[10px] text-xs p-3 border border-rose-300 rounded ml-2" onClick={() => handleClick(index + 1)}>{productName.typeName}</button>
                            )
                        })
                    }
                </div>
                {/* <div>
                <h1 className="text-amber ml-5 left-10">SẢN PHẨM</h1>

            </div> */}
                {
                    items.map((product) => {
                        return (
                            <div className="w-[90%] h-full bg-white justify-items-center mb-5 mx-auto">
                                <div className="w-full h-[50px] bg-amber-500 justify-center">
                                    <h1 className="h-full xl:text-lg lg:text-base sm:text-sm text-white ml-5 mx-auto content-center">{product.typeName}</h1>
                                </div >

                                <div className='w-full h-full grid lg:grid-cols-4 md:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-6 px-6 bg-white mt-5'>
                                    {
                                        product.listProductByType.map((item) => {
                                            return (
                                                <div className='group justify-items-center p-2 mb-5 border border-solid border-slate-200 rounded hover:shadow-xl' onClick={() => handleClickProductDetail(item.id)}>
                                                    <div className='h-[40vh] w-full overflow-hidden'>
                                                        <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={item.img} />
                                                    </div>
                                                    <div className="divide-y divide-gray-400 w-full text-center">
                                                        <h4 className="h-10 my-2 xl:text-base lg:text-sm sm:text-xs">{item.name}</h4>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })

                }
                {/* <div className="w-full h-[50px] bg-sky-600 justify-center">
                    <h1 className=" h-full text-white ml-5 justify-items-center">{typeName}</h1>
                </div > */}

                {/* <div className='w-full h-full grid grid-cols-4 gap-6 px-6 bg-white mt-5'>
                    {
                        listProductName.listProductName.map((item) => {
                            return (
                                <div className='group justify-items-center'>
                                    <div className='h-[40vh] w-full overflow-hidden'>
                                        <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={item.img} />
                                    </div>
                                    <div className="divide-y divide-gray-400 w-full">
                                        <h4 className="h-12 my-2 lg:text-lg sm:text-xs">{item.name}</h4>
                                    </div>
                                </div>
                            )
                        })
                    } */}

                {/* <div className='group justify-items-center'>
                        <div className='h-[40vh] w-full overflow-hidden'>
                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct1} />
                        </div>
                        <div className="divide-y divide-gray-400 w-full">
                            <h4 className="h-12 my-2 lg:text-lg sm:text-xs">GỖ KEO GHÉP THANH</h4>
                        </div>
                    </div>
                    <div className='group justify-items-center'>
                        <div className='h-[40vh] w-full overflow-hidden'>
                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct2} />
                        </div>
                        <div className="divide-y divide-gray-400 w-full">
                            <h4 className="h-12 my-2 lg:text-lg  sm:text-xs">GỖ GHÉP XOAN</h4>
                        </div>
                    </div>
                    <div className='group justify-items-center'>
                        <div className='h-[40vh] w-full overflow-hidden'>
                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                        </div>
                        <div className="divide-y divide-gray-400 w-full">
                            <h4 className="h-12 my-2 lg:text-lg  sm:text-xs">GỖ TRÀM GHÉP THANH</h4>
                        </div>
                    </div>
                    <div className='group justify-items-center'>
                        <div className='h-[40vh] w-full overflow-hidden'>
                            <img className='h-full w-full bg-cover bg-center transition duration-500 scale-100 hover:scale-110' src={imgProduct3} />
                        </div>
                        <div className="divide-y divide-gray-400 w-full">
                            <h4 className="h-12 my-2 lg:text-lg  sm:text-xs">GỖ THÔNG GHÉP THANH</h4>
                        </div>

                    </div> */}
                {/* </div> */}
            </div >
            <Footer />
            <Social />
        </div>
        // </div>
    )
};